
import { all, call, fork, put, takeEvery } from 'redux-saga/effects';
import { EDIT_CONTACT_BY_FIELD, SET_CONTACT_TO_RESTAURANT, SET_PARTNER_CONTACTS, } from '../constants/actionTypes';
import { editContactByFieldFailed, editContactByFieldSuccess, setContactToRestaurantFailed, setContactToRestaurantSuccess, setPartnerContactsFailed, setPartnerContactsSuccess } from './actions';
import { editContactByFieldServices, setContactPartnerServices, setContactToRestaurantServices } from './service';

function* setPartnerContacts({ payload }) {
  try {
    const response = yield call(setContactPartnerServices, payload);
    yield put(setPartnerContactsSuccess(response));
  } catch (error) {
    yield put(setPartnerContactsFailed(error.message));
  }
}



function* setContactToRestaurantSAGA({ payload }) {
  try {
    const response = yield call(setContactToRestaurantServices, payload);
    yield put(setContactToRestaurantSuccess(response));
  } catch (error) {
    yield put(setContactToRestaurantFailed(error.message));
  }
}

function* editContactByFieldSAGA({ payload }) {
  try {
    console.log({ payload })
    const response = yield call(editContactByFieldServices, payload);
    yield put(editContactByFieldSuccess(response));
  } catch (error) {
    yield put(editContactByFieldFailed(error.message));
  }
}



export function* watchSetPartnerContacts() {
  yield takeEvery(SET_PARTNER_CONTACTS, setPartnerContacts);
}

export function* watchSetContactToResturant() {
  yield takeEvery(SET_CONTACT_TO_RESTAURANT, setContactToRestaurantSAGA);
}


export function* watchEditContactByField() {
  yield takeEvery(EDIT_CONTACT_BY_FIELD, editContactByFieldSAGA);
}

function* ContactSaga() {
  yield all([
    fork(watchSetPartnerContacts),
    fork(watchSetContactToResturant),
    fork(watchEditContactByField)
  ]);
}

export default ContactSaga;