import { REHYDRATE } from "redux-persist";
import { GET_GOOGLE_BUSINESS_DATA, GET_GOOGLE_BUSINESS_DATA_BY_NAME, GET_GOOGLE_BUSINESS_DATA_BY_NAME_FAILED, GET_GOOGLE_BUSINESS_DATA_BY_NAME_SUCCESS, GET_GOOGLE_BUSINESS_DATA_FAILED, GET_GOOGLE_BUSINESS_DATA_SUCCESS } from "../constants/actionTypes";

const INIT = {
    data: null,
    isLoading: false,
    error: null
}

const GoogleBusinessReducer = (state = INIT, action) => {
    switch (action.type) {
        case GET_GOOGLE_BUSINESS_DATA:
            return { ...state, isLoading: true }
        case GET_GOOGLE_BUSINESS_DATA_SUCCESS:
            return { ...state, isLoading: false, data: action.payload }
        case GET_GOOGLE_BUSINESS_DATA_FAILED:
            return { ...state, isLoading: false, error: action.payload }

        case GET_GOOGLE_BUSINESS_DATA_BY_NAME:
            return { ...state, isLoading: true }
        case GET_GOOGLE_BUSINESS_DATA_BY_NAME_SUCCESS:
            return { ...state, isLoading: false, data: action.payload }
        case GET_GOOGLE_BUSINESS_DATA_BY_NAME_FAILED:
            return { ...state, isLoading: false, error: action.payload }
            
        case REHYDRATE:
            return action.payload
                ? {
                    ...state,
                    ...action.payload.GoogleBusinessReducer,
                }
                : {
                    ...state,
                };
        default:
            return state;
    }
}

export default GoogleBusinessReducer