import { Box, IconButton, Paper, Stack, Typography } from "@mui/material";
import React from "react";
import OpenInNewIcon from "@mui/icons-material/OpenInNew";
import EditIcon from "@mui/icons-material/Edit";
import RemoveRedEyeIcon from "@mui/icons-material/RemoveRedEye";
import VisibilityOffIcon from "@mui/icons-material/VisibilityOff";
import links from "../../../helpers/links.json";
import defaultImage from "../../../assets/img/default-image.png"

const CartService = ({
  style,
  data,
  handleOpenDetails,
  handleOpenModalEdit,
  handleChangeVisibility,
}) => {
  const { name, shortDesc, longDesc, price, unit, commission, clients, visible, image } = data;

  return (
    <Stack component={Paper} sx={style}>
      <Box
        component="img"
        src={image?.path ? `${links.SERVER_BASE_FRONT}/${image?.path}` : defaultImage}
        alt=""
        sx={{ width: "100%", height: "200px", objectFit: "cover" }}
      />
      <Stack spacing={2} sx={{ padding: "10px" }}>
        <Typography variant="h6">{name}</Typography>
        <Typography>{shortDesc}</Typography>
        <Typography>
          Prix :
          <Typography variant="h6" component="span">
            {price}{" "}
            {unit !== "none" && (
              <>
                / <span style={{ fontSize: "15px" }}>{unit}</span>
              </>
            )}
          </Typography>
        </Typography>
        <Stack direction="row" justifyContent={"space-between"} alignItems={"center"}>
          <IconButton
            onClick={handleChangeVisibility}
            size="large"
            sx={{
              borderRadius: "4px",
              padding: "5px",
              cursor: "pointer",
              fontSize: "23px",
              color: "#fff",
              backgroundColor: visible ? "#54B435" : "#ED2B2A",
              "&:hover": {
                backgroundColor: visible ? "#379237" : "#D21312",
              },
            }}
          >
            {visible ? (
              <RemoveRedEyeIcon fontSize="inherit" />
            ) : (
              <VisibilityOffIcon fontSize="inherit" />
            )}
          </IconButton>
          <Stack spacing={1} direction="row" justifyContent={"space-between"} alignItems={"center"}>
            <IconButton onClick={handleOpenDetails}>
              <OpenInNewIcon />
            </IconButton>
            <IconButton onClick={handleOpenModalEdit}>
              <EditIcon />
            </IconButton>
          </Stack>
        </Stack>
      </Stack>
    </Stack>
  );
};

export default CartService;
