import { REHYDRATE } from "redux-persist";
import {
  SET_PARTNER_RESAUTRANT_CONTACTS,
  SET_PARTNER_RESAUTRANT_CONTACTS_FAILED,
  SET_PARTNER_RESAUTRANT_CONTACTS_SUCCESS,
  EDIT_RESTAURANT_BY_FIELD,
  GET_RESTAURANT_BY_ID,
  GET_RESTAURANT_BY_ID_SUCCESS,
  GET_RESTAURANT_BY_ID_FAILED,
  EDIT_RESTAURANT_BY_ID,
  EDIT_RESTAURANT_BY_ID_SUCCESS,
  EDIT_RESTAURANT_BY_ID_FAILED,
  GET_PARTNER_STATISTICS,
  GET_PARTNER_STATISTICS_SUCCESS,
  GET_PARTNER_STATISTICS_FAILED,
} from "../constants/actionTypes";

const INIT_STATE = {
  contacts: [],
  restaurant: {},
  statistics : {},
  isLoading: false,
  error: null,
  pageCount: 0,
};
const PartnerRestaurantContact = (state = INIT_STATE, action) => {
  console.log({ action });
  switch (action.type) {
    case SET_PARTNER_RESAUTRANT_CONTACTS:
      return { ...state, isLoading: true, error: null };
    case SET_PARTNER_RESAUTRANT_CONTACTS_SUCCESS:
      console.log('action.payload.data :>> ', action.payload.data);
      return {
        ...state,
        isLoading: false,
        error: null,
        contacts: action.payload.data,
        pageCount: action.payload.pageCount,
      };
    case SET_PARTNER_RESAUTRANT_CONTACTS_FAILED:
      return { ...state, isLoading: false, error: action.payload };

    case EDIT_RESTAURANT_BY_FIELD:
      let t = JSON.parse(JSON.stringify(state.contacts));
      let tIndex = t.findIndex((el) => el._id === action.payload.rowID);
      t[tIndex][action.payload.name] = action.payload.value;
      return { ...state, isLoading: false, error: null, contacts: t };

    case GET_RESTAURANT_BY_ID:
      return { ...state, isLoading: true, error: null };
    case GET_RESTAURANT_BY_ID_SUCCESS:
      return { ...state, isLoading: false, error: null, restaurant: action.payload };
    case GET_RESTAURANT_BY_ID_FAILED:
      return { ...state, isLoading: false, error: action.payload };
    case EDIT_RESTAURANT_BY_ID:
      return { ...state, isLoading: true, error: null };
    case EDIT_RESTAURANT_BY_ID_SUCCESS:
      return { ...state, isLoading: false, error: null, contacts: action.payload };
    case EDIT_RESTAURANT_BY_ID_FAILED:
      return { ...state, isLoading: false, error: action.payload };

    case GET_PARTNER_STATISTICS:
      return {...state, isLoading : true, error : null};
    case GET_PARTNER_STATISTICS_SUCCESS:
      return {...state, isLoading : false, error : null, statistics : action.payload }
    case GET_PARTNER_STATISTICS_FAILED:
      return {...state, isLoading : false, error : action.payload}

    case REHYDRATE:
      return action.payload
        ? {
            ...state,
            ...action.payload.PartnerRestaurantContact,
          }
        : {
            ...state,
          };
    default:
      return { ...state };
  }
};

export default PartnerRestaurantContact;
