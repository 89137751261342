import { Box, Button, Modal, Stack, TextField, Typography } from "@mui/material";
import React from "react";

const boxStyle = {
  backgroundColor: "#fff",
  width: { xs: "90%", md: "500px" },
  padding: "20px",
  borderRadius: "15px",
  border: "none",
  display: "flex",
  flexDirection: "column",
  gap: "15px",
  alignItems: "center",
};

const ModalNote = ({ open, handleClose, data, note, setNote, handleSave }) => {
  return (
    <Modal
      style={{ display: "flex", justifyContent: "center", alignItems: "center" }}
      open={open}
      onClose={handleClose}
    >
      <Box sx={boxStyle}>
        <Typography variant="h5">{`Prendre note ${data?.nomRestaurant}, ${data?.city}`}</Typography>
        <TextField
          onChange={(e) => setNote(e.target.value)}
          value={note}
          fullWidth
          multiline
          maxRows={6}
          minRows={6}
          variant="outlined"
        />
        <Stack spacing={2} direction="row">
          <Button onClick={handleClose} disableElevation variant="outlined" size="small">
            Annuler
          </Button>
          <Button
            onClick={() => handleSave(data._id)}
            disableElevation
            variant="contained"
            size="small"
          >
            Confirmer
          </Button>
        </Stack>
      </Box>
    </Modal>
  );
};

export default ModalNote;
