import { GET_GOOGLE_BUSINESS_DATA, GET_GOOGLE_BUSINESS_DATA_BY_NAME, GET_GOOGLE_BUSINESS_DATA_BY_NAME_FAILED, GET_GOOGLE_BUSINESS_DATA_BY_NAME_SUCCESS, GET_GOOGLE_BUSINESS_DATA_FAILED, GET_GOOGLE_BUSINESS_DATA_SUCCESS } from "../constants/actionTypes";

export const getGoogleBusinessData = (data) => ({
    type: GET_GOOGLE_BUSINESS_DATA,
    payload: data
})

export const getGoogleBusinessDataSuccess = (data) => ({
    type: GET_GOOGLE_BUSINESS_DATA_SUCCESS,
    payload: data
})


export const getGoogleBusinessDataFailed = (error) => ({
    type: GET_GOOGLE_BUSINESS_DATA_FAILED,
    payload: error
})

export const getGoogleBusinessDataByName = (data) => ({
    type: GET_GOOGLE_BUSINESS_DATA_BY_NAME,
    payload: data
})

export const getGoogleBusinessDataByNameSuccess = (data) => ({
    type: GET_GOOGLE_BUSINESS_DATA_BY_NAME_SUCCESS,
    payload: data
})


export const getGoogleBusinessDataByNameFailed = (error) => ({
    type: GET_GOOGLE_BUSINESS_DATA_BY_NAME_FAILED,
    payload: error
})