import { REHYDRATE } from "redux-persist";
import {
  EDIT_PARTNER_SERVICE_BY_ID,
  EDIT_PARTNER_SERVICE_BY_ID_FAILED,
  EDIT_PARTNER_SERVICE_BY_ID_SUCCESS,
  GET_PARTNER_SERVICES,
  GET_PARTNER_SERVICES_FAILED,
  GET_PARTNER_SERVICES_SUCCESS,
  SET_PARTNER_SERVICES,
  SET_PARTNER_SERVICES_FAILED,
  SET_PARTNER_SERVICES_SUCCESS,
} from "../constants/actionTypes";

const INIT_STATE = {
  services: [],
  isLoading: false,
  error: null,
};

const PartnerServices = (state = INIT_STATE, action) => {
  switch (action.type) {
    
    case SET_PARTNER_SERVICES:
      return { ...state, isLoading: true, error: null };
    case SET_PARTNER_SERVICES_SUCCESS:
      return { ...state, isLoading: false, error: null, services: action.payload };
    case SET_PARTNER_SERVICES_FAILED:
      return { ...state, isLoading: false, error: action.payload };

    case GET_PARTNER_SERVICES:
      return { ...state, isLoading: true, error: null };
    case GET_PARTNER_SERVICES_SUCCESS:
      return { ...state, isLoading: false, error: null, services: action.payload };
    case GET_PARTNER_SERVICES_FAILED:
      return { ...state, isLoading: false, error: action.payload };

    case EDIT_PARTNER_SERVICE_BY_ID:
      return { ...state, isLoading: true, error: null };
    case EDIT_PARTNER_SERVICE_BY_ID_SUCCESS:
      return { ...state, isLoading: false, error: null, services: action.payload };
    case EDIT_PARTNER_SERVICE_BY_ID_FAILED:
      return { ...state, isLoading: false, error: action.payload };

    case REHYDRATE:
      return action.payload
        ? {
            ...state,
            ...action.payload.PartnerServices,
          }
        : {
            ...state,
          };
    default:
      return { ...state };
  }
};

export default PartnerServices;
