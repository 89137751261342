import React, { useEffect } from "react";
import { faCashRegister, faChartLine } from "@fortawesome/free-solid-svg-icons";
import { Col, Row } from "@themesberg/react-bootstrap";
import { CounterWidget } from "../../components/Widgets";
import { useDispatch, useSelector } from "react-redux";
import { getPartnerStatistics } from "../../redux/partnerRestaurantContact/actions";
import { Backdrop, CircularProgress } from "@mui/material";

export default () => {
  const dispatch = useDispatch();

  const { statistics, isLoading } = useSelector((state) => state.PartnerRestaurantContact);

  useEffect((e) => {
    dispatch(getPartnerStatistics());
  }, []);

  return (
    <>
      {isLoading && (
        <Backdrop sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }} open={true}>
          <CircularProgress color="inherit" />
        </Backdrop>
      )}
      <Row className="">
        <Col xs={12} sm={4} xl={3} className="mb-4 px-1">
          <CounterWidget
            category="Number restaurants"
            title={statistics?.numberRestaurant || "0"}
            period="Feb 1 - Apr 1"
            percentage={18.2}
            icon={faChartLine}
            iconColor="shape-secondary"
          />
        </Col>

        <Col xs={12} sm={4} xl={3} className="mb-4 px-1">
          <CounterWidget
            category="Number Abonnés"
            title={statistics?.numberClient || "0"}
            period="Feb 1 - Apr 1"
            percentage={28.4}
            icon={faCashRegister}
            iconColor="shape-tertiary"
          />
        </Col>
        <Col xs={12} sm={4} xl={3} md className="mb-4 px-1">
          <CounterWidget
            category="Turnover Abonnés"
            title="$0"
            period="Feb 1 - Apr 1"
            percentage={28.4}
            icon={faCashRegister}
            iconColor="shape-tertiary"
          />
        </Col>
        <Col xs={12} sm={4} xl={3} md className="mb-4 px-1">
          <CounterWidget
            category="Total Commission"
            title="$0"
            period="Feb 1 - Apr 1"
            percentage={28.4}
            icon={faCashRegister}
            iconColor="shape-tertiary"
          />
        </Col>
      </Row>
    </>
  );
};
