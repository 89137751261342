import { all, call, fork, put, takeEvery } from "redux-saga/effects";
import {
  editPartnerServiceByIDFailed,
  editPartnerServiceByIDSuccess,
  getPartnerServicesFailed,
  getPartnerServicesSuccess,
  setPartnerServicesFailed,
  setPartnerServicesSuccess,
} from "./actions";
import {
  editPartnerServiceByIDService,
  getServicesPartnerService,
  setServicesPartnerService,
} from "./service";
import {
  EDIT_PARTNER_SERVICE_BY_ID,
  GET_PARTNER_SERVICES,
  SET_PARTNER_SERVICES,
} from "../constants/actionTypes";

function* setPartnerServicesSaga({ payload }) {
  try {
    const response = yield call(setServicesPartnerService, payload);
    yield put(setPartnerServicesSuccess(response));
  } catch (error) {
    yield put(setPartnerServicesFailed(error.message));
  }
}
function* getPartnerServicesSaga({ payload }) {
  try {
    const response = yield call(getServicesPartnerService, payload);
    yield put(getPartnerServicesSuccess(response));
  } catch (error) {
    yield put(getPartnerServicesFailed(error.message));
  }
}

function* editPartnerServiceByIDSaga({ payload }) {
  try {
    const response = yield call(editPartnerServiceByIDService, payload);
    yield put(editPartnerServiceByIDSuccess(response));
  } catch (error) {
    yield put(editPartnerServiceByIDFailed(error.message));
  }
}

export function* watchSetPartnerServices() {
  yield takeEvery(SET_PARTNER_SERVICES, setPartnerServicesSaga);
}

export function* watchGetPartnerServices() {
  yield takeEvery(GET_PARTNER_SERVICES, getPartnerServicesSaga);
}

export function* watchEditPartnerServiceByID() {
  yield takeEvery(EDIT_PARTNER_SERVICE_BY_ID, editPartnerServiceByIDSaga);
}

function* ServicesSaga() {
  yield all([
    fork(watchSetPartnerServices),
    fork(watchGetPartnerServices),
    fork(watchEditPartnerServiceByID),
  ]);
}

export default ServicesSaga;
