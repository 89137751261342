import { httpClient_get, httpClient_post } from '../../helpers/api';
// import API from "./../../services/api"

const loginService = (email, password) => {
    return httpClient_post('/partners/auth', {
        email: email,
        password: password,
    })
        .then((response) => {
            return response.data;
        })
        .catch((error) => {
            console.log('-----error------', error.response);
            throw error.response.data;
        });
};

const registerService = (email, password) => {
    return httpClient_post('/partners/register', { email, password })
        .then((response) => {
            return response.data;
        })
        .catch((error) => {
            console.log('-----error------', error.response);
            throw error.response.data;
        });
};


const updateService = (frname, ltname, birthday, gender, phone, adresse, adresseNo, company, service, city, state, zipcode) => {
    const data = {
        frname, ltname, birthday, gender, phone, adresse, adresseNo, company, city, state, zipcode, service
    }
    return httpClient_post('/partners/upadate_user', { ...data })
        .then((response) => {
            return response.data;
        })
        .catch((error) => {
            console.log('-----error------', error.response);
            throw error.response.data;
        });
};

// const resetPasswordService = (
//   password,
//   r_password,
//   id_user,
//   token,
// ) => {
//   return httpClient_post(`/auth/new-password/${id_user}/${token}`, {
//     newPassword: password,
//     confirmPassword: r_password,
//   })
//     .then((response) => {
//       return response.data;
//     })
//     .catch((error) => {
//       throw error.response.data;
//     });
// };
// const changeLanguageService = (lang) => {
//   console.log({ lang });
//   return httpClient_post(`/users//change-language`, {
//     lang,
//   })
//     .then((response) => {
//       return response.data;
//     })
//     .catch((error) => {
//       throw error.response.data;
//     });
// };
export {
    loginService,
    registerService,
    updateService
    //   forgotPasswordService,
    //   resetPasswordService,
    //   changeLanguageService,
};