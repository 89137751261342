import { Box, Button, Modal, Stack, TextField } from "@mui/material";
import React from "react";

const ModalAddRestau = ({ open, handleClose, data, handleChange, handleSaveButton }) => {
  const boxStyle = {
    backgroundColor: "#fff",
    width: { xs: "90%", md: "650px" },
    padding: "25px",
    borderRadius: "15px",
    border: "none",
  };

  return (
    <Modal
      style={{ display: "flex", justifyContent: "center", alignItems: "center" }}
      open={open}
      onClose={handleClose}
    >
      <Box sx={boxStyle}>
        <Stack spacing={2} alignItems="flex-end">
          <Stack sx={{ width: "100%" }} direction="column" spacing={2}>
            <Stack sx={{ width: "100%" }} spacing={2} direction="row">
              <TextField
                fullWidth
                variant="outlined"
                size="small"
                label="Nom de restaurant"
                value={data.nomRestaurant}
                onChange={(e) => handleChange("nomRestaurant", e.target.value)}
              />
              <TextField
                fullWidth
                variant="outlined"
                size="small"
                label="Ville"
                value={data.city}
                onChange={(e) => handleChange("city", e.target.value)}
              />
            </Stack>

            <Stack sx={{ width: "100%" }} spacing={2} direction="row">
              <TextField
                fullWidth
                variant="outlined"
                size="small"
                label="Siteweb"
                value={data.siteWeb}
                onChange={(e) => handleChange("siteWeb", e.target.value)}
              />
              <TextField
                fullWidth
                variant="outlined"
                size="small"
                label="Résérvation"
                value={data.BookingLink}
                onChange={(e) => handleChange("BookingLink", e.target.value)}
              />
            </Stack>

            <Stack sx={{ width: "100%" }} spacing={2} direction="row">
              <TextField
                fullWidth
                variant="outlined"
                size="small"
                label="Menu"
                value={data.MenuLink}
                onChange={(e) => handleChange("MenuLink", e.target.value)}
              />
              <TextField
                fullWidth
                variant="outlined"
                size="small"
                label="Owner"
                value={data.Owner}
                onChange={(e) => handleChange("Owner", e.target.value)}
              />
            </Stack>
            <Stack sx={{ width: "100%" }} spacing={2} direction="row">
              <TextField
                fullWidth
                type = "tel"
                variant="outlined"
                size="small"
                label="Téléphone"
                value={data.phone}
                onChange={(e) => handleChange("phone", e.target.value)}
              />
               <TextField
                fullWidth
                type = "email"
                variant="outlined"
                size="small"
                label="Email"
                value={data.email}
                onChange={(e) => handleChange("email", e.target.value)}
              />
            </Stack>
          </Stack>
          <Button disableElevation variant="outlined" size="small" onClick={handleSaveButton}>
            Enregistrer
          </Button>
        </Stack>
      </Box>
    </Modal>
  );
};

export default ModalAddRestau;
