import { httpClient_post, httpClient_get } from "../../helpers/api";

const setServicesPartnerService = (data) => {
  return httpClient_post("/partners/add_partner_services", data, {'Content-Type': 'multipart/form-data'})
    .then((response) => {
      return response.data.data;
    })
    .catch((error) => {
      throw error.response.data;
    });
};

const getServicesPartnerService = (data) => {
  return httpClient_get("/partners/get-partner-services", data)
    .then((res) => {
      return res.data.data;
    })
    .catch((err) => {
      throw err.response.data;
    });
};

const editPartnerServiceByIDService = (data) => {
  return httpClient_post("/partners/edit-partner-service-by-id", data, {'Content-Type': 'multipart/form-data'})
    .then((res) => {
      return res.data.data;
    })
    .catch((err) => {
      throw err.response.data;
    });
};
export { setServicesPartnerService, getServicesPartnerService, editPartnerServiceByIDService };
