import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSearch } from "@fortawesome/free-solid-svg-icons";
import {
  Backdrop,
  Button,
  CircularProgress,
  InputAdornment,
  Stack,
  TextField,
  styled,
} from "@mui/material";
import { makeStyles } from "@mui/styles";
import { useState } from "react";
import ModalAddService from "./components/Services/ModalAddService";
import { useDispatch, useSelector } from "react-redux";
import {
  getPartnerServices,
  setPartnerServices,
  editPartnerServiceByID,
} from "../redux/partnerServices/actions";
import CartService from "./components/Services/CartService";
import ModalServiceDetails from "./components/Services/ModalServiceDetails";
import { useEffect } from "react";
import ModalEditService from "./components/Services/ModalEditService";

const CssTextField = styled(TextField)({
  "& .MuiOutlinedInput-root": {
    borderRadius: "8px",
    overflow: "hidden",
    backgroundColor: "#fff",
    "& fieldset": {
      borderColor: "#D1D7E0",
      borderWidth: "1px",
    },
    "&:hover fieldset": {
      borderColor: "#D1D7E0",
      borderWidth: "1px",
    },
    "&.Mui-focused fieldset": {
      borderColor: "#D1D7E0",
      borderWidth: "1px",
    },
  },
});

const useStyles = makeStyles({
  button: {
    textTransform: "capitalize",
    alignSelf: "flex-end",
    fontSize: "17px",
    padding: "5px 15px",
  },
});

const Services = () => {
  const dispatch = useDispatch();
  const classes = useStyles();
  const [openModalAdd, setOpenModalAdd] = useState(false);
  const [openModalDetails, setOpenModalDetails] = useState(false);
  const [serviceDetails, setServiceDetails] = useState([]);
  const [openModalEdit, setOpenModalEdit] = useState(false);
  const [serviceEditData, setServiceEditData] = useState({});
  const [data, setData] = useState([
    {
      name: "nameService",
      rowName: "Nom service",
      value: "",
    },
    {
      name: "shortDesc",
      rowName: "Brève description",
      value: "",
    },
    {
      name: "longDesc",
      rowName: "Longue description",
      value: "",
    },
    {
      name: "price",
      rowName: "Prix",
      value: "",
    },
    {
      name: "unit",
      rowName: "Unit",
      value: "",
    },
    {
      name: "commission",
      rowName: "Commission",
      value: "",
    },
    {
      name: "clients",
      rowName: "Reference Clients",
      value: [],
    },
    {
      name: "visible",
      rowName: "Public aux clients",
      value: false,
    },
    { name: "file", rowName: "Upload", value: null },
  ]);
  const [filter, setFilter] = useState("");

  useEffect((e) => {
    dispatch(getPartnerServices());
  }, []);

  const { services, isLoading } = useSelector((state) => state.PartnerServices);

  const handleCloseModalAdd = () => {
    setOpenModalAdd(false);
    setData([
      {
        name: "nameService",
        rowName: "Nom service",
        value: "",
      },
      {
        name: "shortDesc",
        rowName: "Brève description",
        value: "",
      },
      {
        name: "longDesc",
        rowName: "Longue description",
        value: "",
      },
      {
        name: "unit",
        rowName: "Unit",
        value: "",
      },
      {
        name: "price",
        rowName: "Prix",
        value: "",
      },
      {
        name: "commission",
        rowName: "Commission",
        value: "",
      },
      {
        name: "clients",
        rowName: "Reference Clients",
        value: [],
      },
      {
        name: "visible",
        rowName: "Public aux clients",
        value: false,
      },
      { name: "file", rowName: "Upload", value: null },
    ]);
  };

  const handleChangeModalAdd = (name, value) => {
    setData(
      data.map((el) => {
        if (el.name === name) {
          return { ...el, value: value };
        }
        return el;
      })
    );
  };

  const handleSaveModalAddData = () => {
    const form = new FormData();
    form.append("name", data.find((el) => el.name === "nameService")?.value);
    form.append("shortDesc", data.find((el) => el.name === "shortDesc")?.value);
    form.append("longDesc", data.find((el) => el.name === "longDesc")?.value);
    form.append("price", data.find((el) => el.name === "price")?.value);
    form.append("unit", data.find((el) => el.name === "unit")?.value || "none");
    form.append("commission", data.find((el) => el.name === "commission")?.value);

    for (const iterator of data.find((el) => el.name === "clients")?.value) {
      form.append("clients[]", iterator);
    }

    form.append("visible", data.find((el) => el.name === "visible")?.value);
    form.append("file", data.find((el) => el.name === "file")?.value);

    // console.log(Object.fromEntries(form));
    dispatch(setPartnerServices(form));
    handleCloseModalAdd();
  };

  const handleCloseModalDetails = () => {
    setOpenModalDetails(false);
  };

  const handleOpenModalDetails = (service) => {
    setOpenModalDetails(true);
    console.log(service);
    setServiceDetails(service);
  };

  const handleOpenModalEdit = (service) => {
    setOpenModalEdit(true);
    setServiceEditData(service);
  };

  const handleCloseModalEdit = () => {
    setOpenModalEdit(false);
    setServiceEditData({});
  };

  const handleChangeEditService = (name, value) => {
    setServiceEditData({ ...serviceEditData, [name]: value });
  };

  const handleSaveEdit = () => {
    const form = new FormData();
    form.append("_id", serviceEditData._id);
    form.append("name", serviceEditData.name);
    form.append("shortDesc", serviceEditData.shortDesc);
    form.append("longDesc", serviceEditData.longDesc);
    form.append("price", serviceEditData.price);
    form.append("unit", serviceEditData.unit || "none");
    form.append("commission", serviceEditData.commission);

    // Get ids from array of objects
    let clientArray = serviceEditData.clients
      ?.filter((el) => el !== undefined)
      ?.map((el) => el._id);

    for (const client of clientArray) {
      form.append("clients[]", client);
    }

    form.append("visible", serviceEditData.visible);
    form.append("file", serviceEditData.image);

    dispatch(editPartnerServiceByID(form));
    setOpenModalEdit(false);
  };

  const handleChangeVisibility = (service) => {
    dispatch(editPartnerServiceByID({ _id: service._id, visible: !service.visible }));
  };

  const handleChangeSearch = (event) => {
    setFilter(event.target.value);
  };

  return (
    <Stack>
      {isLoading && (
        <Backdrop sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }} open={true}>
          <CircularProgress color="inherit" />
        </Backdrop>
      )}
      <Stack direction="column" spacing={2} justifyContent="space-between" alignItems="flex-start">
        <Stack direction="row" spacing={1} alignSelf={"flex-end"}>
          <Button
            className={classes.button}
            disableElevation
            size="small"
            variant="outlined"
            onClick={() => setOpenModalAdd(true)}
          >
            Ajouter
          </Button>
        </Stack>
        <CssTextField
          sx={{ width: { xs: "100%", md: "500px" } }}
          size="small"
          placeholder="Search for a service..."
          variant="outlined"
          value={filter}
          onChange={handleChangeSearch}
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <FontAwesomeIcon icon={faSearch} />
              </InputAdornment>
            ),
          }}
        />
      </Stack>
      <Stack
        sx={{
          gap: "20px",
          minHeight: "470px",
          flexDirection: "row",
          justifyContent: "flex-start",
          alignItems: "center",
          padding: "25px 0",
          flexWrap: "wrap",
        }}
      >
        {services
          .filter((el) => el.name.toLowerCase().includes(filter.toLowerCase()))
          ?.map((service, index) => (
            <CartService
              key={index}
              data={service}
              style={{ width: { xs: "100%", sm: "48%", lg: "32%" } }}
              handleOpenDetails={() => handleOpenModalDetails(service)}
              handleOpenModalEdit={() => handleOpenModalEdit(service)}
              handleChangeVisibility={() => handleChangeVisibility(service)}
            />
          ))}
      </Stack>
      <ModalAddService
        open={openModalAdd}
        handleClose={handleCloseModalAdd}
        handleChange={handleChangeModalAdd}
        handleSaveData={handleSaveModalAddData}
        data={data}
      />

      <ModalServiceDetails
        open={openModalDetails}
        handleClose={handleCloseModalDetails}
        data={serviceDetails}
      />

      <ModalEditService
        open={openModalEdit}
        data={serviceEditData}
        handleClose={handleCloseModalEdit}
        handleSaveData={handleSaveEdit}
        handleChange={handleChangeEditService}
      />
    </Stack>
  );
};

export default Services;
