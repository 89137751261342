import React, { useEffect, useState } from "react";
import { Route, Switch, Redirect } from "react-router-dom";
import { Routes } from "../routes";


import DashboardOverview from "./dashboard/DashboardOverview";
import Transactions from "./Transactions";
import Client from "./Client";
import Settings from "./Settings";
import Signin from "./Signin";
import Signup from "./Signup";
import BootstrapTables from "./tables/BootstrapTables";

import Sidebar from "../components/Sidebar";
import Navbar from "../components/Navbar";
import Footer from "../components/Footer";
import { store } from "./../redux"
// import { Provider } from 'react-redux'
import {
  getLoggedInUser,
  isUserAuthenticated,
} from './../helpers/authUtils';
import Raport from "./Raport";
import contact from "./Contacts"
import MyRestau from "./MyRestau"
import Services from "./Services"

const RouteWithSidebar = ({ component: Component, ...rest }) => {
  useEffect(() => {
    // (async () => {
    //   await fetch("http://localhost:8000/api/user", {
    //     headers: {
    //       "Content-Type": "application/json",
    //     },
    //     credentials: "include",
    //   });
    // })();
  }, []);


  return (
    <Route
      {...rest}
      render={(props) => {
        const isAuthTokenValid = isUserAuthenticated();
        if (!isAuthTokenValid) {
          return <Redirect to={"signin"} />;
        }
        return (
          <>

            <Sidebar />
            <main className="content" style={{ display: "flex", flexDirection: "column", height: "100vh" }}>
              <Navbar />
              <Component {...props} />
              <div style={{ flexGrow: 1 }} />
              <Footer />
            </main>
          </>
        );
      }}
    />
  );
};

export default () => (
  // <Provider store={store}>
  <Switch>
    {/* <RouteWithLoader exact path={Routes.Signin.path} component={Signin} />
    <RouteWithLoader exact path={Routes.Signup.path} component={Signup} />
    <RouteWithLoader
      exact
      path={Routes.ForgotPassword.path}
      component={ForgotPassword}
    />
    <RouteWithLoader
      exact
      path={Routes.ResetPassword.path}
      component={ResetPassword}
    />

    <RouteWithLoader
      exact
      path={Routes.NotFound.path}
      component={NotFoundPage}
    />
    <RouteWithLoader
      exact
      path={Routes.ServerError.path}
      component={ServerError}
    /> */}

    {/* pages */}
    <Route exact path={Routes.signin.path} component={Signin} />
    <Route exact path={Routes.signup.path} component={Signup} />
    <RouteWithSidebar
      exact
      path={Routes.DashboardOverview.path}
      component={DashboardOverview}
    />
    <RouteWithSidebar exact path={Routes.Client.path} component={Client} />
    <RouteWithSidebar exact path={Routes.raport.path} component={Raport} />
    <RouteWithSidebar exact path={Routes.myRestau.path} component={MyRestau} />
    <RouteWithSidebar exact path={Routes.contacts.path} component={contact} />
    <RouteWithSidebar exact path={Routes.services.path} component={Services} />

    <RouteWithSidebar
      exact
      path={Routes.Transactions.path}
      component={Transactions}
    />
    <RouteWithSidebar exact path={Routes.profile.path} component={Settings} />

    <RouteWithSidebar
      exact
      path={Routes.contact.path}
      component={contact}
    />
    {/* <RouteWithSidebar
      exact
      path={Routes.BootstrapTables.path}
      component={BootstrapTables}
    /> */}
    <Redirect to={Routes.DashboardOverview.path} />
  </Switch>
  // </Provider>
);
