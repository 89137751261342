import React, { useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEnvelope, faUnlockAlt } from "@fortawesome/free-solid-svg-icons";
import {
  Col,
  Row,
  Form,
  Card,
  Button,
  Container,
  InputGroup,
  Alert
} from "@themesberg/react-bootstrap";
import { Link, Redirect } from "react-router-dom";
import { Routes } from "../routes";
import openflow from "../assets/img/openflow_image.svg";
import "./Signin.css";
import logo from "../assets/img/logo_openflow.svg";
import swal from "sweetalert";
import { isUserAuthenticated } from "../helpers/authUtils";
import { useDispatch, useSelector } from "react-redux";
import { loginUser } from "../redux/users/actions";
const Signin = () => {
  const [email, setemail] = useState("");
  const [password, setPassword] = useState("");
  const [redirecte, setRedirect] = useState(false);

  const dispatch = useDispatch();
  const { error } = useSelector(state => state.Users)
  const handlelogin = (event, values) => {

    dispatch(
      loginUser(
        email,
        password,
        () => {
          setRedirect(e => !e)
        },
      ),
    );
  };

  const renderRedirectToRoot = () => {
    const isAuthTokenValid = isUserAuthenticated();
    if (isAuthTokenValid) {
      return <Redirect to="/" />;
    }
  };

  return (
    <main>
      {renderRedirectToRoot()}
      <div className="tout">
        <img src={logo} alt="" className="logo_openflow" />

        <Button
          variant="primary"
          as={Link}
          to={Routes.signup.path}
          className="button_sign"
        >
          Sign up
        </Button>
      </div>{" "}
      <section className="d-flex align-items-center my-6 mt-lg-6 mb-lg-5 contain">
        <Container>
          <Row className="justify-content-center form-bg-image">
            <Col
              xs={12}
              className="d-flex align-items-center justify-content-center"
            >
              <img src={openflow} alt="" className="image" />
              <div className="bg-white shadow-soft border rounded border-light p-4 w-100 fmxw-450">
                <div className="text-center text-md-center mb-4 mt-md-0">
                  <h3 className="mb-0">Sign in to our platform</h3>

                </div>
                {error && <Alert key={"danger"} variant={"danger"}>
                  {error}
                </Alert>}
                <Form className="mt-4" onSubmit={handlelogin}>
                  <Form.Group id="email" className="mb-4">
                    <Form.Label>Your Email</Form.Label>
                    <InputGroup>
                      <InputGroup.Text>
                        <FontAwesomeIcon icon={faEnvelope} />
                      </InputGroup.Text>
                      <Form.Control
                        autoFocus
                        required
                        type="email"
                        placeholder="example@company.com"
                        value={email}
                        onChange={(e) => setemail(e.target.value)}
                      />
                    </InputGroup>

                  </Form.Group>
                  <Form.Group>
                    <Form.Group id="password" className="mb-4">
                      <Form.Label>Your Password</Form.Label>
                      <InputGroup>
                        <InputGroup.Text>
                          <FontAwesomeIcon icon={faUnlockAlt} />
                        </InputGroup.Text>
                        <Form.Control
                          required
                          type="password"
                          placeholder="Password"
                          value={password}
                          onChange={(e) => setPassword(e.target.value)}
                        />
                      </InputGroup>
                    </Form.Group>
                  </Form.Group>
                  <Button
                    variant="primary"
                    type="button"
                    onClick={() => handlelogin()}
                    className="w-100 button_sign"
                  >
                    Sign in
                  </Button>
                </Form>

                <div className="d-flex justify-content-center align-items-center mt-4">
                  <span className="fw-normal">
                    Not registered?
                    <Card.Link
                      as={Link}
                      to={Routes.signup.path}
                      className="fw-bold"
                    >
                      {` Create account `}
                    </Card.Link>
                  </span>
                </div>
              </div>
            </Col>
          </Row>
        </Container>
      </section>
    </main>
  );
};
export default Signin;
