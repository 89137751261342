import { Box, Modal, Stack, Typography } from "@mui/material";
import React from "react";
import links from "../../../helpers/links.json";
import defaultImage from "../../../assets/img/default-image.png"

const boxStyle = {
  backgroundColor: "#fff",
  width: { xs: "90%", md: "800px" },
  padding: "0",
  overflow: "hidden",
  borderRadius: "15px",
  border: "none",
  alignItems: "center",
};
const ModalServiceDetails = ({ handleClose, open, data }) => {
  const { name, shortDesc, longDesc, price, commission, clients, visible, image, unit } = data;

  return (
    <Modal
      sx={{ display: "flex", justifyContent: "center", alignItems: "center" }}
      open={open}
      onClose={handleClose}
    >
      <Stack spacing={4} sx={boxStyle}>
        <Stack
          sx={{ width: "100%", height: { xs: "unset", md: "500px" } }}
          direction={{ xs: "column-reverse", md: "row" }}
          justifyContent={"space-between"}
        >
          <Stack spacing={2} sx={{ flex: "1", padding: "15px" }}>
            <Typography variant="h4" sx={{ fontWeight: "700", color: "#4a5073" }}>
              {name}
            </Typography>
            <Box>
              <Typography sx={{ fontWeight: "700" }}>Brève description : </Typography>
              <Typography variant="body2">{shortDesc}</Typography>
            </Box>

            <Box>
              <Typography sx={{ fontWeight: "700" }}>Longue description : </Typography>
              <Typography variant="body2">{longDesc}</Typography>
            </Box>

            <Typography sx={{ fontWeight: "700" }}>
              Prix :{" "}
              <span style={{ fontWeight: "500" }}>
                {price}{" "}
                {unit !== "none" && (
                  <>
                    / <span style={{ fontSize: "15px" }}>{unit}</span>
                  </>
                )}
              </span>
            </Typography>
            <Typography sx={{ fontWeight: "700" }}>
              OpenFlow commission : <span style={{ fontWeight: "500" }}>{commission}</span>
            </Typography>
            <Typography sx={{ fontWeight: "700" }}>
              Visibilité :
              <span style={{ fontWeight: "500" }}>{visible ? "Visible" : "Cachée"}</span>
            </Typography>
            <Typography sx={{ fontWeight: "700" }}>
              Clients :
              <span style={{ fontWeight: "500" }}>
                {clients?.map((el) => el.nomRestaurant).join(", ")}
              </span>
            </Typography>
          </Stack>
          <Box
            component={"img"}
            sx={{
              objectFit: "cover",
              width: { xs: "100%", md: "350px" },
              height: { xs: "300px", md: "unset" },
            }}
            src={image?.path ? `${links.SERVER_BASE_FRONT}/${image?.path}` : defaultImage}
            alt={name}
          />
        </Stack>
      </Stack>
    </Modal>
  );
};

export default ModalServiceDetails;
