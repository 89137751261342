import {
  Box,
  Button,
  FormControl,
  FormControlLabel,
  IconButton,
  InputLabel,
  MenuItem,
  Modal,
  Select,
  Stack,
  Switch,
  TextField,
  Typography,
} from "@mui/material";
import React, { useEffect, useRef, useState } from "react";
import { useSelector } from "react-redux";
import links from "../../../helpers/links.json";
import CameraAltIcon from "@mui/icons-material/CameraAlt";
import UndoIcon from "@mui/icons-material/Undo";
import { makeStyles } from "@mui/styles";
import defaultImage from "../../../assets/img/default-image.png"

const useStyles = makeStyles({
  input: {
    "& input[type=number]": {
      "-moz-appearance": "textfield",
    },
    "& input[type=number]::-webkit-outer-spin-button": {
      "-webkit-appearance": "none",
      margin: 0,
    },
    "& input[type=number]::-webkit-inner-spin-button": {
      "-webkit-appearance": "none",
      margin: 0,
    },
  },
  boxStyle: {
    backgroundColor: "#fff",
    overflow: "hidden",
    borderRadius: "15px",
    border: "none",
    alignItems: "center",
  },
});

const ModalEditService = ({ open, data, handleClose, handleSaveData, handleChange }) => {
  const { contacts: restaurants } = useSelector((state) => state.PartnerRestaurantContact);
  const imageRef = useRef(null);
  const [imageSrc, setImageSrc] = useState(``);
  const [imageChanged, setImageChanged] = useState(false);
  const [oldImage, setOldImage] = useState(null);
  const classes = useStyles();

  useEffect(() => {
    // We put this check because if we change the data in the other input this run again
    if (data?.image?.path) {
      setOldImage(`${links.SERVER_BASE_FRONT}/${data?.image?.path}`);
      setImageSrc(`${links.SERVER_BASE_FRONT}/${data?.image?.path}`);
    }
  }, [data]);

  const handleImageChange = (event) => {
    // Check if the user select an image
    // Because when hi select image and click on change => cancel shows error
    // event.target.files[0] => undefined
    if (event.target.files[0]) {
      setImageChanged(true);
      handleChange("image", event.target.files[0]);
      let value = URL.createObjectURL(event.target.files[0]);
      setImageSrc(value);
    }
  };

  const handleCancelImageChange = () => {
    setImageSrc(oldImage);
    setImageChanged(false);
  };

  const buttonStyles = {
    backgroundColor: "#fff",
    color: "#000",
    borderColor: "#000",
    borderRadius: "5px",
    border: "none",
    "&:hover": {
      border: "none",
      backgroundColor: "#fff",
      color: "#000",
      borderColor: "#000",
    },
  };

  return (
    <Modal
      sx={{ display: "flex", justifyContent: "center", alignItems: "center" }}
      open={open}
      onClose={() => {
        handleClose();
        setOldImage(null);
        setImageChanged(false);
        setImageSrc("")
      }}
    >
      <Stack spacing={1} className={classes.boxStyle} sx= {{width : {xs : "90%", md : "700px"}}}>
        <Box sx={{ width: "100%", position: "relative" }}>
            <Box
              component={"img"}
              alt="text"
              src={imageSrc || defaultImage}
              sx={{ width: "100%", height: "280px", objectFit: "cover", objectPosition: "center" }}
            />
          <Stack
            direction="row"
            spacing={1}
            sx={{ position: "absolute", bottom: "10px", right: "10px" }}
          >
            {imageChanged && (
              <IconButton onClick={handleCancelImageChange} size="small" sx={buttonStyles}>
                <UndoIcon />
              </IconButton>
            )}
            <Button
              onClick={() => imageRef.current.click()}
              sx={buttonStyles}
              disableElevation
              variant="outlined"
              size="small"
              endIcon={<CameraAltIcon />}
            >
              Changer
              <input
                id="upload"
                name="upload"
                type="file"
                ref={imageRef}
                hidden
                accept="image/*"
                onChange={handleImageChange}
              />
            </Button>
          </Stack>
        </Box>
        <Typography variant="h5">Modifier service</Typography>
        <Stack spacing={1} sx={{ width: "100%", padding: "15px" }}>
          <TextField
            fullWidth
            variant="outlined"
            size="small"
            label={"Nom Service"}
            value={data.name}
            onChange={(e) => handleChange("name", e.target.value)}
          />
          <TextField
            fullWidth
            variant="outlined"
            size="small"
            label={"Brève description"}
            value={data.shortDesc}
            onChange={(e) => handleChange("shortDesc", e.target.value)}
          />
          <TextField
            fullWidth
            variant="outlined"
            size="small"
            label={"Longue description"}
            value={data.longDesc}
            onChange={(e) => handleChange("longDesc", e.target.value)}
          />
          <Stack direction="row" spacing={1} justifyContent={"space-between"} alignItems={"center"}>
            <TextField
              sx={{ width: "50%", "& .MuiInputBase-root": { padding: 0 } }}
              variant="outlined"
              size="small"
              type="number"
              className={classes.input}
              label={"Prix"}
              value={data.price}
              onChange={(e) => handleChange("price", e.target.value)}
              InputProps={{
                endAdornment: (
                  <Select
                    sx={{ width: "100px", ".MuiOutlinedInput-notchedOutline": { border: "unset" } }}
                    size="small"
                    value={data.unit}
                    onChange={(e) => handleChange("unit", e.target.value)}
                  >
                    <MenuItem value="none">None</MenuItem>
                    <MenuItem value="Kg">Kg</MenuItem>
                    <MenuItem value="L">L</MenuItem>
                    <MenuItem value="Unit">Unit</MenuItem>
                    <MenuItem value="mois">Mois</MenuItem>
                  </Select>
                ),
              }}
            />
            <TextField
              sx={{ width: "50%" }}
              variant="outlined"
              size="small"
              label={"Commission"}
              value={data.commission}
              onChange={(e) => handleChange("commission", e.target.value)}
            />
          </Stack>
          <FormControl size="small" fullWidth>
            <InputLabel id="selectId">Référence Clients</InputLabel>
            <Select
              labelId="selectId"
              size="small"
              multiple
              label={"Référence Clients"}
              value={data.clients?.map((el) => el?._id)}
              onChange={(e) => {
                let values = restaurants?.filter((restau) => {
                  if (e.target.value.includes(restau._id)) {
                    return {
                      _id: restau._id,
                      nomRestaurant: restau.nomRestaurant,
                      city: restau.city,
                    };
                  }
                });
                handleChange("clients", values);
              }}
            >
              {restaurants?.map((restau) => {
                return (
                  <MenuItem key={restau._id} value={restau._id}>
                    {restau.nomRestaurant}
                  </MenuItem>
                );
              })}
            </Select>
          </FormControl>

          <Stack spacing={1} direction="row" justifyContent={"space-between"} alignItems={"center"}>
            <FormControlLabel
              control={
                <Switch
                  checked={data.visible}
                  onChange={(e) => handleChange("visible", e.target.checked)}
                />
              }
              label={"Public aux clients"}
            />
            <Stack direction="row" spacing={2}>
              <Button
                disableElevation
                variant="outlined"
                size="small"
                onClick={() => {
                  handleClose();
                  setOldImage(null);
                  setImageChanged(false);
                  setImageSrc("");
                }}
              >
                Annuler
              </Button>
              <Button
                disableElevation
                variant="contained"
                size="small"
                onClick={() => {
                  setOldImage(null);
                  setImageChanged(false);
                  handleSaveData();
                }}
              >
                Sauvegarder
              </Button>
            </Stack>
          </Stack>
        </Stack>
      </Stack>
    </Modal>
  );
};

export default ModalEditService;
