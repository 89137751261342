
import { all, call, fork, put, takeEvery } from 'redux-saga/effects';

import {
  GET_AFFILIATION_CLIENT,
} from '../constants/actionTypes';

import {
  getAffiliationClientFailed,
  getAffiliationClientSuccess
} from './actions';
import {
  getClientService
} from './service';

function* getClient({ payload }) {

  try {
    const response = yield call(getClientService);
    yield put(getAffiliationClientSuccess(response));

  } catch (error) {
    yield put(getAffiliationClientFailed(error.message));

  }
}


export function* watchGetClient() {
  yield takeEvery(GET_AFFILIATION_CLIENT, getClient);
}



function* ClientSaga() {
  yield all([
    fork(watchGetClient),
  ]);
}

export default ClientSaga;