import {
  EDIT_RESTAURANT_BY_FIELD,
  EDIT_RESTAURANT_BY_FIELD_FAILED,
  EDIT_RESTAURANT_BY_FIELD_SUCCESS,
  SET_PARTNER_RESAUTRANT_CONTACTS,
  SET_PARTNER_RESAUTRANT_CONTACTS_FAILED,
  SET_PARTNER_RESAUTRANT_CONTACTS_SUCCESS, 
  GET_RESTAURANT_BY_ID,
  GET_RESTAURANT_BY_ID_SUCCESS,
  GET_RESTAURANT_BY_ID_FAILED,
  EDIT_RESTAURANT_BY_ID,
  EDIT_RESTAURANT_BY_ID_SUCCESS, 
  EDIT_RESTAURANT_BY_ID_FAILED,
  GET_PARTNER_STATISTICS,
  GET_PARTNER_STATISTICS_SUCCESS,
  GET_PARTNER_STATISTICS_FAILED
} from "../constants/actionTypes";

export const setPartnerResautrantContacts = (data, page, filtringFields) => ({
  type: SET_PARTNER_RESAUTRANT_CONTACTS,
  payload: { data, page, filtringFields },
});
export const setPartnerResautrantContactsSuccess = (data, pageCount) => ({
  type: SET_PARTNER_RESAUTRANT_CONTACTS_SUCCESS,
  payload: { data, pageCount },
});

export const setPartnerResautrantContactsFailed = (error) => ({
  type: SET_PARTNER_RESAUTRANT_CONTACTS_FAILED,
  payload: error,
});

export const editRestaurantByField = (data) => ({
  type: EDIT_RESTAURANT_BY_FIELD,
  payload: data
})

export const editRestaurantByFieldSuccess = (data) => ({
  type: EDIT_RESTAURANT_BY_FIELD_SUCCESS,
  payload: data
})

export const editRestaurantByFieldFailed = (error) => ({
  type: EDIT_RESTAURANT_BY_FIELD_FAILED,
  payload: error
})

export const getRestaurantByID = (data) => ({
  type: GET_RESTAURANT_BY_ID,
  payload: data
})

export const getRestaurantByIDSuccess = (data) => ({
  type: GET_RESTAURANT_BY_ID_SUCCESS,
  payload: data
})

export const getRestaurantByIDFailed = (error) => ({
  type: GET_RESTAURANT_BY_ID_FAILED,
  payload: error
})

export const editRestaurantByID = ({data}) => ({
  type : EDIT_RESTAURANT_BY_ID, 
  payload : data
})

export const editRestaurantByIDSuccess = (data) => ({
  type : EDIT_RESTAURANT_BY_ID_SUCCESS, 
  payload : data
})

export const editRestaurantByIDFailed = (error) => ({
  type : EDIT_RESTAURANT_BY_ID_FAILED, 
  payload : error
})

export const getPartnerStatistics = (data) => ({
  type : GET_PARTNER_STATISTICS, 
  payload : data
})

export const getPartnerStatisticsSuccess = (data) => ({
  type : GET_PARTNER_STATISTICS_SUCCESS, 
  payload : data
})

export const getPartnerStatisticsFailed = ({error}) => ({
  type : GET_PARTNER_STATISTICS_FAILED,
  payload : error
})