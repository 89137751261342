import { persistStore, persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage';
import { legacy_createStore as createStore, applyMiddleware, compose } from 'redux';
import createSagaMiddleware from 'redux-saga';
import rootReducer from './reducer';
import { configureStore } from '@reduxjs/toolkit';
import rootSaga from './sagas';



const composeEnhancers =
  (process.env.NODE_ENV === 'development' &&
    window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__) ||
  compose;

//     const store = createStore(
//         reducers,
//         initialState,
//         composeEnhancers(
//             applyMiddleware(...middlewares)
//         ),
//     );

//     let persistor = persistStore(store)

//     return { store, persistor, sagaMiddleware }

// // }

const persistConfig = {
  key: 'root',
  storage,
};

const persistedReducer = persistReducer(persistConfig, rootReducer);
const sagaMiddleware = createSagaMiddleware();
let store = configureStore({
  reducer: persistedReducer,
  middleware: (getDefaultMiddleware) => {
    return getDefaultMiddleware({ serializableCheck: false, thunk: true }).prepend(sagaMiddleware);
  },
}
  // persistedReducer,
  // composeEnhancers(applyMiddleware(sagaMiddleware)),
);
let persistor = persistStore(store);


export { store, persistor, sagaMiddleware };