import React from "react";
import ReactDOM from "react-dom";
import { BrowserRouter } from "react-router-dom";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import { positions, Provider as ProviderAlert } from "react-alert";
import AlertTemplate from "react-alert-template-basic";

import { Provider } from 'react-redux'
// core styles
import "./scss/volt.scss";

// vendor styles
import "react-datetime/css/react-datetime.css";

import HomePage from "./pages/HomePage";
import { store, persistor, sagaMiddleware } from "./redux"
import { PersistGate } from 'redux-persist/integration/react';
import sagas from './redux/sagas';

sagaMiddleware.run(sagas);

const options = {
  timeout: 5000,
  position: positions.BOTTOM_CENTER
};


ReactDOM.render(
  <ProviderAlert template={AlertTemplate} {...options}>
    <Provider store={store}>
      <PersistGate persistor={persistor}>
        <BrowserRouter>
          <HomePage />
        </BrowserRouter>
      </PersistGate>
    </Provider>
  </ProviderAlert>,

  document.getElementById("root")
);
