export const Routes = {
  // pages
  DashboardOverview: { path: "/" },
  Transactions: { path: "/transactions" },
  Client: { path: "/Client" },
  raport: { path: "/raport" },
  myRestau: { path: "/mes-restaurants" },
  contacts: { path: "/contacts" },
  profile: { path: "/profile" },
  signin: { path: "/signin" },
  signup: { path: "/signup" },
  contact: { path: "/contact" },
  services : {path : "/services"}
};

