import { httpClient_get, httpClient_post } from '../../helpers/api';

const setContactPartnerServices = (data) => {
    return httpClient_post('/partners/add_partner_contact', {
        ...data
    })
        .then((response) => {
            return response.data;
        })
        .catch((error) => {
            throw error.response.data;
        });
};


const setContactToRestaurantServices = (data) => {
    return httpClient_post('/partners/link_contact_to_restaurant', {
        ...data
    })
        .then((response) => {
            console.log("-=-=->>>>>>", response.data)
            return response.data;
        })
        .catch((error) => {
            throw error.response.data;
        });
};

const editContactByFieldServices = (data) => {
    return httpClient_post('/partners/edit_contact_by_field', {
        ...data
    })
        .then((response) => {
            console.log("-=-=->>>>>>", response)
            return response.data;
        })
        .catch((error) => {
            throw error.response.data;
        });
};

export {
    setContactPartnerServices,
    setContactToRestaurantServices,
    editContactByFieldServices
};