// defaults to localStorage for web
import { persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage';

import { combineReducers } from 'redux';

import { LOGOUT_USER } from './constants/actionTypes';
import Users from './users/reducer';
import Client from "./client/reducer"
import PartnerRestaurantContact from './partnerRestaurantContact/reducer';
import partnerContact from './partnerContact/reducer';
import GoogleBusinessReducer from './googleBusiness/reducer';
import PartnerServices from "./partnerServices/reducer"

const persistConfig = {
  key: 'root',
  storage: storage,
  // whitelist: ['Module', 'Quiz'],
};

const appReducer = combineReducers({
  Users,
  Client,
  PartnerRestaurantContact,
  partnerContact,
  GoogleBusinessReducer, 
  PartnerServices
});
const rootReducer = (state, action) => {
  if (action.type === LOGOUT_USER) {
    // for all keys defined in your persistConfig(s)
    storage.removeItem('persist:root');
    // storage.removeItem('persist:otherKey')

    state = undefined;
  }
  return appReducer(state, action);
};

export default persistReducer(persistConfig, rootReducer);
//export default appReducer