import { httpClient_post } from '../../helpers/api';

const getGoogleBusinessService = (data) => {
    return httpClient_post('/partners/get_google_business_data', {
        placeId: data
    })
        .then((response) => {
            return response.data.data;
        })
        .catch((error) => {
            throw error.response.data;
        });
};

const getGoogleBusinessServiceByName = (data) => {
    return httpClient_post('/partners/get_google_business_data_by_name', {
        name: data
    })
        .then((response) => {
            return response.data;
        })
        .catch((error) => {
            throw error.response.data;
        });
};


const getGoogleBusinessByNameService = (data) => {
    return httpClient_post('/partners/get_google_business_data_by_name', {
        name: data
    })
        .then((response) => {
            return response.data.data;
        })
        .catch((error) => {
            throw error.response.data;
        });
};


export {
    getGoogleBusinessService,
    getGoogleBusinessByNameService,
    getGoogleBusinessServiceByName
};