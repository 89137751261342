
import { REHYDRATE } from 'redux-persist';

// @flow
import {
    GET_AFFILIATION_CLIENT,
    GET_AFFILIATION_CLIENT_SUCCESS,
    GET_AFFILIATION_CLIENT_FAILED
} from '../constants/actionTypes';

let INIT_STATE = {
    client: [],
    loading: false,
    error: null,
};
const Client = (state = INIT_STATE, action) => {

    switch (action.type) {
        case GET_AFFILIATION_CLIENT:
            return { ...state, loading: true };
        case GET_AFFILIATION_CLIENT_SUCCESS:
            return { ...state, client: action.payload, loading: false, error: null, };
        case GET_AFFILIATION_CLIENT_FAILED:
            return { ...state, error: action.payload, loading: false, };
            
        case REHYDRATE:
            return action.payload
                ? {
                    ...state,
                    ...action.payload.Client,
                }
                : {
                    ...state,
                };
        default:
            return { ...state };
    }
};

export default Client;