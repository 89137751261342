import { Box, Button, Modal, Stack, TextField } from "@mui/material";
import React from "react";

const ModalAddContact = ({ open, handleClose, data, handleChange, handleSaveButton }) => {
  const boxStyle = {
    backgroundColor: "#fff",
    width: { xs: "90%", md: "650px" },
    padding: "25px",
    borderRadius: "15px",
    border: "none",
  };

  return (
    <Modal
      style={{ display: "flex", justifyContent: "center", alignItems: "center" }}
      open={open}
      onClose={handleClose}
    >
      <Box sx={boxStyle}>
        <Stack spacing={2} alignItems="flex-end">
          <Stack sx={{ width: "100%" }} direction="column" spacing={2}>
            <Stack sx={{ width: "100%", gap: "10px", flexDirection: "row" }}>
              <TextField
                sx={{ width: "50%" }}
                variant="outlined"
                size="small"
                label="Nom"
                value={data.name}
                onChange={(e) => handleChange("name", e.target.value)}
              />
              <TextField
                sx={{ width: "50%" }}
                variant="outlined"
                size="small"
                label="Prénom"
                value={data.prenom}
                onChange={(e) => handleChange("prenom", e.target.value)}
              />
            </Stack>

            <Stack sx={{ width: "100%", gap: "10px", flexDirection: "row" }}>
              <TextField
                sx={{ width: "50%" }}
                variant="outlined"
                size="small"
                label="Email"
                value={data.email}
                onChange={(e) => handleChange("email", e.target.value)}
              />
              <TextField
                sx={{ width: "50%" }}
                variant="outlined"
                size="small"
                label="Phone"
                value={data.phone}
                onChange={(e) => handleChange("phone", e.target.value)}
              />
            </Stack>
            <Stack sx={{ width: "100%", gap: "10px", flexDirection: "row", justifyContent : "space-between" }}>
              <TextField
                sx={{ width: "calc(50% - 5px)" }}
                variant="outlined"
                size="small"
                label="Poste"
                value={data.job}
                onChange={(e) => handleChange("job", e.target.value)}
              />
              <Button
                sx={{ width: "20%" }}
                disableElevation
                variant="outlined"
                size="small"
                onClick={handleSaveButton}
              >
                Enregistrer
              </Button>
            </Stack>
          </Stack>
        </Stack>
      </Box>
    </Modal>
  );
};

export default ModalAddContact;
