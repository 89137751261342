import { all, call, fork, put, takeEvery, takeLatest } from "redux-saga/effects";
import {
  EDIT_RESTAURANT_BY_FIELD,
  EDIT_RESTAURANT_BY_ID,
  GET_RESTAURANT_BY_ID,
  GET_PARTNER_STATISTICS,
  SET_PARTNER_RESAUTRANT_CONTACTS,
} from "../constants/actionTypes";
import {
  editRestaurantByFieldFailed,
  editRestaurantByFieldSuccess,
  editRestaurantByIDFailed,
  getRestaurantByIDFailed,
  getRestaurantByIDSuccess,
  setPartnerResautrantContactsFailed,
  setPartnerResautrantContactsSuccess,
  editRestaurantByIDSuccess,
  getPartnerStatisticsSuccess,
  getPartnerStatisticsFailed
} from "./actions";
import { editRestaurantByFieldServices, getRestaurantByIDService, setContactPartnerServices, editRestaurantByIDService, getPartnerStatisticsService } from "./service";

function* setPartnerContacts({ payload }) {
  try {
    const response = yield call(setContactPartnerServices, payload);
    yield put(setPartnerResautrantContactsSuccess(response.data, response.pageCount));
  } catch (error) {
    yield put(setPartnerResautrantContactsFailed(error.message));
  }
}

function* editRestaurantByField({ payload }) {
  try {
    const response = yield call(editRestaurantByFieldServices, payload);
    yield put(editRestaurantByFieldSuccess(response));
  } catch (error) {
    yield put(editRestaurantByFieldFailed(error.message));
  }
}

function* getRestaurantByIDSaga({payload}){
  try {
    const response = yield call(getRestaurantByIDService, payload); 
    yield put(getRestaurantByIDSuccess(response))
  } catch (error) {
    yield put(getRestaurantByIDFailed(error))
  }
}

function* editRestaurantByIDSaga({payload}){
  try {
    const response= yield call(editRestaurantByIDService, payload); 
    yield put(editRestaurantByIDSuccess(response.data))
  } catch (error) {
    yield put(editRestaurantByIDFailed(error))
  }
}

function* getPartnerStatisticsSaga({payload}){
  try {
    const response = yield call(getPartnerStatisticsService, payload);
    yield put(getPartnerStatisticsSuccess({...response}))
  } catch (error) {
    yield put(getPartnerStatisticsFailed(error))
    
  }
}

export function* watchSetPartnerContacts() {
  yield takeLatest(SET_PARTNER_RESAUTRANT_CONTACTS, setPartnerContacts);
}

export function* watchEditRestaurantByField() {
  yield takeEvery(EDIT_RESTAURANT_BY_FIELD, editRestaurantByField);
}

export function* watchGetRestaurantByID() {
  yield takeEvery(GET_RESTAURANT_BY_ID, getRestaurantByIDSaga)
}

export function* watchEditRestaurantByID() {
  yield takeEvery(EDIT_RESTAURANT_BY_ID, editRestaurantByIDSaga)
}

export function* watchGetPartnerStatistics() {
  yield takeEvery(GET_PARTNER_STATISTICS, getPartnerStatisticsSaga)
}

function* ContactSaga() {
  yield all([
    fork(watchSetPartnerContacts), 
    fork(watchEditRestaurantByField), 
    fork(watchGetRestaurantByID), 
    fork(watchEditRestaurantByID), 
    fork(watchGetPartnerStatistics)
  ]);
}

export default ContactSaga;