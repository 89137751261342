import moi from "../assets/img/team/moi.jpeg";

export default [
  {
    id: 1,
    read: false,
    image: moi,
    sender: "Elabd iliass",
    time: "a few moments ago",
    link: "#",
    message: `Added you to an event "Project stand-up" tomorrow at 12:30 AM.`,
  },
];
