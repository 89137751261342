import React, { useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import { Redirect } from "react-router-dom";
import { faEnvelope, faUnlockAlt } from "@fortawesome/free-solid-svg-icons";
import {
  Col,
  Row,
  Form,
  Card,
  Button,
  FormCheck,
  Container,
  InputGroup,
  Alert,
} from "@themesberg/react-bootstrap";
import Recaptcha from "react-google-invisible-recaptcha";
import { Link } from "react-router-dom";
import { Routes } from "../routes";
import openflow from "../assets/img/openflow_image.svg";
import logo from "../assets/img/logo_openflow.svg";
import "./Signin.css";
import swal from "sweetalert";
import { isUserAuthenticated } from "../helpers/authUtils";
import { useDispatch, useSelector } from "react-redux";
import { registerUser } from "../redux/users/actions";

const Signup = () => {
  const [email, setEmail] = useState("");
  const [password, setpassword] = useState("");
  const [redirecte, setRedirect] = useState(false);
  const dispatch = useDispatch()
  const { error } = useSelector(state => state.Users)

  const handleSubmit = async (e) => {
    dispatch(registerUser({
      email,
      password,
      callback: () => {
        setRedirect((e) => !e)
      }
    }))
  };

  const renderRedirectToRoot = () => {
    const isAuthTokenValid = isUserAuthenticated();
    if (isAuthTokenValid) {
      return <Redirect to="/" />;
    }
  };
  return (
    <main>
      {renderRedirectToRoot()}
      <div className="tout">
        <img src={logo} alt="" className="logo_openflow" />

        <Button
          variant="primary"
          as={Link}
          to={Routes.signin.path}
          className="button_sign"
        >
          Login
        </Button>
      </div>{" "}
      <section className="d-flex align-items-center my-6 mt-lg-6 mb-lg-5">
        <Container>
          <Row className="justify-content-center form-bg-image">
            <Col
              xs={12}
              className="d-flex align-items-center justify-content-center"
            >
              <img src={openflow} alt="" className="imagee" />
              <div className="mb-4 mb-lg-5 bg-white shadow-soft border rounded border-light p-3 p-lg-4 w-100 fmxw-450">
                <div className="text-center text-md-center mb-4 mt-md-0">
                  <h3 className="mb-0">Become openflow partner</h3>
                </div>
                {error && <Alert key={"danger"} variant={"danger"}>
                  {error}
                </Alert>}
                <Form className="mt-4" >

                  <Form.Group id="email" className="mb-4">
                    <Form.Label>Your Email</Form.Label>
                    <InputGroup>
                      <InputGroup.Text>
                        <FontAwesomeIcon icon={faEnvelope} />
                      </InputGroup.Text>
                      <Form.Control
                        autoFocus
                        required
                        type="email"
                        placeholder="example@company.com"
                        value={email}
                        onChange={(e) => setEmail(e.target.value)}
                      />
                    </InputGroup>
                  </Form.Group>
                  <Form.Group id="password" className="mb-4">
                    <Form.Label>Your password</Form.Label>
                    <InputGroup>
                      <InputGroup.Text>
                        <FontAwesomeIcon icon={faUnlockAlt} />
                      </InputGroup.Text>
                      <Form.Control
                        required
                        type="password"
                        placeholder="password"
                        value={password}
                        onChange={(e) => setpassword(e.target.value)}
                      />
                    </InputGroup>
                  </Form.Group>
                  <Form.Group
                    style={{
                      marginLeft: "auto",
                      marginRight: "auto",
                    }}
                  ></Form.Group>

                  <FormCheck type="checkbox" className="d-flex mb-4 mt-3">
                    <FormCheck.Input required id="terms" className="me-2" />
                    <FormCheck.Label htmlFor="terms">
                      I agree to the <Card.Link>terms and conditions</Card.Link>
                    </FormCheck.Label>
                  </FormCheck>

                  <Button
                    variant="primary"
                    type="Button"
                    className="w-100 button_sign"
                    onClick={handleSubmit}
                  >
                    Sign up
                  </Button>
                </Form>

                <div className="d-flex justify-content-center align-items-center mt-4">
                  <span className="fw-normal">
                    Already have an account?
                    <Card.Link
                      as={Link}
                      to={Routes.signin.path}
                      className="fw-bold"
                    >
                      {`Login here`}
                    </Card.Link>
                  </span>
                </div>
              </div>
            </Col>
            <Recaptcha sitekey="6LdOMGUlAAAAACA4-9w1DQNpQP7Wh6N1XSkEs8d2" />
          </Row>
        </Container>
      </section>
    </main>
  );
};
export default Signup;
