import { authHeader } from './authUtils';
import links from './links.json';
import axios from 'axios';


const baseURL = links.api;

const httpClient = axios.create({});

/**
 *
 * @param {string} url
 * @param {object} data
 */

const httpClient_post = (
  url,
  data,
  header = {},
  baseURL_ = baseURL,
) => {
  httpClient.defaults.headers.common = authHeader(header);
  return httpClient.post(`${baseURL_}${url}`, data);
};

const httpClient_post_s = (
  url,
  data,
  cancelToken,
  baseURL_ = baseURL,
) => {
  return axios({
    method: 'post',
    url: `${baseURL_}${url}`,
    data: data,
    headers: authHeader(),
    cancelToken: cancelToken,
  });
};

const httpClient_get_s = (
  url,
  params,
  cancelToken,
  baseURL_ = baseURL,
) => {
  return axios({
    method: 'get',
    url: `${baseURL_}${url}`,
    params,
    headers: authHeader(),
    cancelToken: cancelToken,
  });
};

const httpClient_get_cancelToken = (
  url,
  cancelToken,
  baseURL_ = baseURL,
) => {
  return axios({
    method: 'get',
    url: `${baseURL_}${url}`,
    headers: authHeader(),
    cancelToken: cancelToken,
  });
};

/**
 *
 * @param {string} url
 * @param {object} data
 */
const httpClient_get = (url, params = {}) => {
  httpClient.defaults.headers.common = authHeader();
  return httpClient.get(`${baseURL}${url}`, { params });
};

/**
 *
 * @param {string} url
 * @param {object} data
 */
const httpClient_delete = (url, data) => {
  return httpClient.delete(`${baseURL}${url}`, data);
};

/**
 *
 * @param {string} url
 * @param {object} data
 */
const httpClient_put = (
  url,
  data,
  header = {},
  baseURL_ = baseURL,
) => {
  httpClient.defaults.headers.common = authHeader(header);
  return httpClient.put(`${baseURL_}${url}`, data);
};
export {
  httpClient,
  httpClient_post,
  httpClient_get,
  httpClient_delete,
  httpClient_put,
  baseURL,
  httpClient_post_s,
  httpClient_get_s,
  httpClient_get_cancelToken,
};