import React from 'react'
import LocationSearchInput from '../components/LocationSearchInput'

const Raport = () => {
  return (
    <div>
      <div style={{ maxWidth: "600px", margin: "0 auto" }}>
        <LocationSearchInput />
      </div>
    </div>
  )
}

export default Raport
