
import React, { useEffect, useState } from "react";
import moment from "moment-timezone";
import Datetime from "react-datetime";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCalendarAlt } from '@fortawesome/free-solid-svg-icons';
import { Col, Row, Card, Form, Button, InputGroup } from '@themesberg/react-bootstrap';
import { useDispatch, useSelector } from "react-redux";
import { updateUser } from "../redux/users/actions";
import axios from "axios";


export const GeneralInfoForm = () => {
  const { user } = useSelector(state => state.Users)
  const dispatch = useDispatch()
  const [countries, setCountries] = useState([])

  const [data, setData] = useState({
    frname: user.name,
    ltname: user.lastname,
    birthday: user.birthday,
    gender: user.gender,
    email: user.email,
    phone: user.phone,
    adresse: user.adresse,
    adresseNo: user.NoAdresse,
    company: user.company,
    service : user.service,
    city: user.City,
    state: user.state,
    zipcode: user.zipCode,
  })

  const handleSubmit = () => {
    // console.log(data)
    dispatch(updateUser({ ...data }))
  }

  useEffect(() => {
    axios.get("https://restcountries.com/v3.1/all").then(res => {
      setCountries(res.data)
    })
  }, [])

  return (
    <Card border="light" className="bg-white shadow-sm mb-4">
      <Card.Body>
        <h5 className="mb-4">Informations générales</h5>
        <Form>
          <Row>
            <Col md={6} className="mb-3">
              <Form.Group id="firstName">
                <Form.Label>Prénom</Form.Label>
                <Form.Control
                  defaultValue={data.frname}
                  onChange={({ target }) => {
                    setData((e) => { return { ...e, frname: target.value } })
                  }}
                  type="text"
                  placeholder="Entrer votre prénom" />
              </Form.Group>
            </Col>
            <Col md={6} className="mb-3">
              <Form.Group id="lastName">
                <Form.Label>Nom</Form.Label>
                <Form.Control
                  defaultValue={data.ltname}
                  onChange={({ target }) => {
                    setData((e) => { return { ...e, ltname: target.value } })
                  }}
                  required type="text" placeholder="Entrer votre nom" />
              </Form.Group>
            </Col>
          </Row>
          <Row className="align-items-center">
            <Col md={6} className="mb-3">
              <Form.Group id="birthday">
                <Form.Label>Date de naissance</Form.Label>
                <Datetime
                  timeFormat={false}
                  onChange={(dt) => {
                    setData((e) => { return { ...e, birthday: dt } })
                  }}
                  renderInput={(props, openCalendar, closeCalendar) => (
                    <InputGroup>
                      <InputGroup.Text><FontAwesomeIcon icon={faCalendarAlt} /></InputGroup.Text>
                      <Form.Control
                        type="text"
                        value={data.birthday ? moment(data.birthday).format("MM/DD/YYYY") : ""}
                        placeholder="mm/dd/yyyy"
                        onFocus={openCalendar}
                        onChange={(dt) => {
                          closeCalendar()
                        }} />
                    </InputGroup>
                  )} />
              </Form.Group>
            </Col>
            <Col md={6} className="mb-3">
              <Form.Group id="gender">
                <Form.Label>Genre</Form.Label>
                <Form.Select onChange={({ target }) => { setData((e) => { return { ...e, gender: target.value } }) }} defaultValue={data.gender}>
                  <option value={null}>Genre</option>
                  <option value={"Female"}>Femme</option>
                  <option value={"Male"}>homme</option>
                </Form.Select>
              </Form.Group>
            </Col>
          </Row>
          <Row>
            <Col md={6} className="mb-3">
              <Form.Group id="emal">
                <Form.Label>Email</Form.Label>
                <Form.Control
                  defaultValue={data.email}
                  disabled type="email" placeholder="Entrer votre email" />
              </Form.Group>
            </Col>
            <Col md={6} className="mb-3">
              <Form.Group id="phone">
                <Form.Label>Téléphone</Form.Label>
                <Form.Control defaultValue={data.phone}
                  onChange={({ target }) => { setData((e) => { return { ...e, phone: target.value } }) }}
                  placeholder="Entrer votre numéro" />
              </Form.Group>
            </Col>
          </Row>

          <h5 className="my-4">Entreprise</h5>
          <Row>
            <Col sm={6} className="mb-3">
              <Form.Group id="address">
                <Form.Label>Nom de l'entreprise</Form.Label>
                <Form.Control
                  defaultValue={data.company}
                  onChange={({ target }) => {
                    setData((e) => { return { ...e, company: target.value } })
                  }}

                  type="text" placeholder="Entrer nom de l'entreprise" />
              </Form.Group>
            </Col>
            <Col sm={6} className="mb-3">
              <Form.Group id="address">
                <Form.Label>Service</Form.Label>
                <Form.Select
                  defaultValue={data.service}
                  onChange={({ target }) => {
                    setData((e) => { return { ...e, service: target.value } })
                  }}
                  id="service">
                    <option value="">Choisir votre service</option>
                    <option value="marketing">Marketing</option>
                    <option value="alimentation">Alimentation et boisson</option>
                    <option value="materiel">Matériel</option>
                </Form.Select>
              </Form.Group>
            </Col>
          </Row>
          <h5 className="my-4">adresse</h5>
          <Row>
            <Col sm={9} className="mb-3">
              <Form.Group id="address">
                <Form.Label>Address</Form.Label>
                <Form.Control
                  defaultValue={data.adresse}
                  onChange={({ target }) => {
                    setData((e) => { return { ...e, adresse: target.value } })
                  }}

                  type="text" placeholder="Enter your home address" />
              </Form.Group>
            </Col>
            <Col sm={3} className="mb-3">
              <Form.Group id="addressNumber">
                <Form.Label>Numéro</Form.Label>
                <Form.Control
                  defaultValue={data.adresseNo}
                  onChange={({ target }) => {
                    setData((e) => { return { ...e, adresseNo: target.value } })
                  }}
                  type="number" placeholder="No." />
              </Form.Group>
            </Col>
          </Row>
          <Row>
          <Col sm={4} className="mb-3">
              <Form.Group className="mb-2">
                <Form.Label>Pays</Form.Label>
                <Form.Select
                  defaultValue={data.state}
                  onChange={({ target }) => {
                    setData((e) => { return { ...e, state: target.value } })
                  }}
                  id="state">
                  <option value="0">Pays</option>
                  {countries?.sort(function(a, b) {
                      return a.name.common.toUpperCase() > b.name.common.toUpperCase() ? 1 : -1
                    })?.map((country, index) => (
                    <option key = {index}  value={country?.name?.common}>{country?.name?.common}</option>
                  ))}
                </Form.Select>
              </Form.Group>
            </Col>
            <Col sm={4} className="mb-3">
              <Form.Group id="city">
                <Form.Label>Ville</Form.Label>
                <Form.Control defaultValue={data.city}
                  onChange={({ target }) => {
                    setData((e) => { return { ...e, city: target.value } })
                  }} type="text" placeholder="Ville" />
              </Form.Group>
            </Col>
            <Col sm={4}>
              <Form.Group id="zip">
                <Form.Label>ZIP</Form.Label>
                <Form.Control defaultValue={data.zipcode}
                  onChange={({ target }) => {
                    setData((e) => { return { ...e, zipcode: target.value } })
                  }}
                  type="tel" placeholder="ZIP" />
              </Form.Group>
            </Col>
          </Row>
          <div className="mt-3 d-flex flex-row-reverse">
            <Button onClick={handleSubmit} variant="primary" type="button">Sauvegarder</Button>
          </div>
        </Form>
      </Card.Body>
    </Card>
  );
};
