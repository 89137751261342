import React, { useEffect } from "react";
import {
  Backdrop,
  Box,
  Button,
  CircularProgress,
  IconButton,
  InputAdornment,
  Stack,
  TextField,
  styled,
} from "@mui/material";
import { Nav, Card, Table, Pagination } from "@themesberg/react-bootstrap";
import { useState } from "react";
import ModalImportRestau from "./components/MyRestau/ModalImportRestau";
import ModalAddRestau from "./components/MyRestau/ModalAddRestau";
import ModalEditRestau from "./components/MyRestau/ModalEditRestau";
import { useRef } from "react";
import readXlsxFile from "read-excel-file";
import { useDispatch, useSelector } from "react-redux";
import {
  editRestaurantByID,
  getRestaurantByID,
  setPartnerResautrantContacts,
} from "../redux/partnerRestaurantContact/actions";
import { getGoogleBusinessDataByName } from "../redux/googleBusiness/actions";
import CreateIcon from "@mui/icons-material/Create";
import CheckIcon from "@mui/icons-material/Check";
import FilterAltIcon from "@mui/icons-material/FilterAlt";
import FilterAltOffIcon from "@mui/icons-material/FilterAltOff";
import EditableInput from "./components/EditableInput";
import EventAvailableIcon from "@mui/icons-material/EventAvailable";
import RestaurantMenuIcon from "@mui/icons-material/RestaurantMenu";
import PublicIcon from "@mui/icons-material/Public";
import ModalNote from "./components/MyRestau/ModalNote";
import NotesIcon from "@mui/icons-material/Notes";
import SearchIcon from "@mui/icons-material/Search";
import DeleteIcon from "@mui/icons-material/Delete";
import VisibilityIcon from "@mui/icons-material/Visibility";
import ModalArchiveRestau from "./components/MyRestau/ModalArchiveRestau";

let timeoutId = null;

const CssTextField = styled(TextField)({
  "& .MuiOutlinedInput-root": {
    borderRadius: "8px",
    overflow: "hidden",
    backgroundColor: "#fff",
    "& fieldset": {
      borderColor: "#D1D7E0",
      borderWidth: "1px",
    },
    "&:hover fieldset": {
      borderColor: "#D1D7E0",
      borderWidth: "1px",
    },
    "&.Mui-focused fieldset": {
      borderColor: "#D1D7E0",
      borderWidth: "1px",
    },
  },
});

const MyRestau = () => {
  const dispatch = useDispatch();
  const [openModal, setOpenModal] = useState(false);
  const [openModalAdd, setOpenModalAdd] = useState(false);
  const [openModalEdit, setOpenModalEdit] = useState(false);
  const [openModalNote, setOpenModalNote] = useState(false);
  const [openModalArchive, setOpenModalArchive] = useState(false);

  const [selectedRestau, setSelectedRestau] = useState({});

  const [note, setNote] = useState("");
  const [showEditFieldID, setShowEditFieldID] = useState(-1);
  const [selectedRowId, setSelectedRowId] = useState(-1);

  const [editedData, setEditedData] = useState([
    { id: 0, name: "nomRestaurant", value: "" },
    { id: 1, name: "city", value: "" },
    { id: 2, name: "siteWeb", value: "" },
    { id: 3, name: "Booking", value: "" },
    { id: 4, name: "Menu", value: "" },
    { id: 5, name: "Owner", value: "" },
    { id: 6, name: "phone", value: "" },
    { id: 7, name: "email", value: "" },
  ]);
  const [file, setFile] = useState();
  const [rows, setRows] = useState([]);
  const [headers, setHeaders] = useState([]);
  const [mappedHeaders, setMappedHeaders] = useState([
    { id: 0, row: "nomRestaurant", value: null },
    { id: 1, row: "city", value: null },
    { id: 2, row: "siteWeb", value: null },
    { id: 3, row: "Booking", value: null },
    { id: 4, row: "Menu", value: null },
    { id: 5, row: "Owner", value: null },
    { id: 6, row: "phone", value: null },
    { id: 7, row: "email", value: null },
  ]);

  const [addOneData, setAddOneData] = useState({
    nomRestaurant: "",
    city: "",
    siteWeb: "",
    BookingLink: "",
    MenuLink: "",
    Owner: "",
    phone: "",
    email: "",
  });
  const [filtringFields, setFiltringFields] = useState([
    { id: 0, rowName: "nomRestaurant", filter: false, value: "" },
    { id: 1, rowName: "city", filter: false, value: "" },
    { id: 2, rowName: "Owner", filter: false, value: "" },
    { id: 3, rowName: "phone", filter: false, value: "" },
  ]);
  const [currentPage, setCurrentPage] = useState(1);

  // Start Edit data in table
  const handleShowEditField = (id, row) => {
    setEditedData([
      { id: 0, name: "nomRestaurant", value: row.nomRestaurant },
      { id: 1, name: "city", value: row.city },
      { id: 2, name: "siteWeb", value: row.siteWeb },
      { id: 3, name: "Booking", value: row.BookingLink },
      { id: 4, name: "Menu", value: row.MenuLink },
      { id: 5, name: "Owner", value: row.Owner },
      { id: 6, name: "phone", value: row.phone },
      { id: 7, name: "email", value: row.email },
    ]);

    setShowEditFieldID(id);
    setSelectedRowId(row._id);
  };

  const handleSaveEditedData = (row) => {
    const data = {};
    data.id = row._id;

    editedData.forEach((item) => {
      const { name, value } = item;
      data[name] = value;

      if (name === "Menu") {
        data["MenuLink"] = value;
        data["Menu"] = value.length !== 0 ? "yes" : "na";
      }

      if (name === "Booking") {
        data["BookingLink"] = value;
        data["Booking"] = value.length !== 0 ? "yes" : "na";
      }
    });

    dispatch(editRestaurantByID({ data }));
    setShowEditFieldID(-1);
  };
  // End Edit data in table

  // Start add one item
  const handleChangeData = (itemHeader, value) => {
    setAddOneData({ ...addOneData, [itemHeader]: value });
  };

  const handleCloseMadalAdd = () => {
    setAddOneData({
      restauName: "",
      ville: "",
      siteweb: "",
      reservation: "",
      menu: "",
      owner: "",
      phone: "",
      email: "",
    });
    setOpenModalAdd(false);
  };

  const handleSaveAddOne = () => {
    const data = addOneData;
    addOneData.MenuLink === "" ? (data.Menu = "na") : (data.Menu = "yes");
    addOneData.BookingLink === "" ? (data.Booking = "na") : (data.Booking = "yes");

    handleCloseMadalAdd();
    const filterObj = {};
    filtringFields.map((element) => {
      filterObj[element.rowName] = element.value;
    })
    dispatch(setPartnerResautrantContacts([data], currentPage - 1, filterObj));
  };
  // End add one item

  // Start Filter table rows
  const handleShowFilter = (id) => {
    setFiltringFields(
      filtringFields.map((el) => (el.id === id ? { ...filtringFields[id], filter: true } : el))
    );
  };
  const handleRemoveFilter = (id) => {
    setFiltringFields(
      filtringFields.map((el) =>
        el.id === id ? { ...filtringFields[id], filter: false, value: "" } : el
      )
    );
  };
  // End Filter table rows

  const { contacts, isLoading, pageCount } = useSelector((state) => state.PartnerRestaurantContact);
  let tableRows = [
    { id: 0, rowName: "Nom de restaurant" },
    { id: 1, rowName: "Ville" },
    { id: 2, rowName: "Site Web" },
    { id: 3, rowName: "Résérvation" },
    { id: 4, rowName: "Menu" },
    { id: 5, rowName: "Owner" },
    { id: 6, rowName: "Téléphone" },
    { id: 7, rowName: "Email" },
  ];

  const fileRef = useRef();

  const handleChange = (event) => {
    setFile(event.target.files[0]);
    readXlsxFile(event.target.files[0]).then((allRows) => {
      setRows(allRows);
    });
  };

  const handleOpenModal = () => {
    setOpenModal(true);
  };
  const handleCloseModal = () => {
    setOpenModal(false);
  };

  const handleChangeHeader = (name, id) => {
    setHeaders([
      ...headers.map((header) => {
        if (header.head === name) {
          return { ...header, selected: true };
        }
        return header;
      }),
    ]);
    setMappedHeaders([
      ...mappedHeaders.map((item) => {
        if (item.id === id) {
          return { ...item, value: name };
        }
        return item;
      }),
    ]);
  };

  // Used by handleSaveButton to create object that contains all the keys
  const createObj = (data = []) => {
    let testObj = {};
    data.map((element) => {
      switch (element.id) {
        case 0:
          testObj.nomRestaurant = "";
          break;
        case 1:
          testObj.city = "";
          break;
        case 2:
          testObj.siteWeb = "";
          break;
        case 3:
          testObj.Booking = "";
          break;
        case 4:
          testObj.Menu = "";
          break;
        case 5:
          testObj.Owner = "";
          break;
        case 6:
          testObj.phone = "";
          break;
        case 7:
          testObj.email = "";
          break;
        default:
          return "";
      }
    });
    return testObj;
  };

  const handleSaveButton = () => {
    setOpenModal(false);

    // const testData = rows.slice(1).map((row) => {
    //   let obj = createObj(mappedHeaders);
    //   if (rows[0][0] === mappedHeaders[0].value) {
    //     obj.nomRestaurant = row[0];
    //   }
    //   if (rows[0][1] === mappedHeaders[1].value) {
    //     obj.city = row[1];
    //   } 
    //   if (rows[0][2] === mappedHeaders[2].value) {
    //     obj.siteWeb = row[2];
    //   }
    //   if (rows[0][3] === mappedHeaders[3].value) {
    //     obj.BookingLink = row[3];
    //     obj.Booking = row[3].length !== 0 ? "yes" : "na";
    //   } 
    //   if (rows[0][4] === mappedHeaders[4].value) {
    //     obj.MenuLink = row[4];
    //     obj.Menu = row[4].length !== 0 ? "yes" : "na";
    //   }
    //   if (rows[0][5] === mappedHeaders[5].value) {
    //     obj.Owner = row[5];
    //   }
    //   if (rows[0][6] === mappedHeaders[6].value) {
    //     obj.phone = row[6];
    //   }
    //   if (rows[0][7] === mappedHeaders[7].value) {
    //     obj.phone = row[7];
    //   }
    //   return obj;
    // });

    const testData = rows.slice(1).map((row) => {
      let obj = createObj(mappedHeaders);
    
      for (let i = 0; i < mappedHeaders.length; i++) {
        const header = mappedHeaders[i];
        const columnIndex = rows[0].indexOf(header.value);

    
        if (columnIndex !== -1) {
          obj[header.row] = row[columnIndex];
        }
    
        if (header.row === 'Booking' || header.row === 'Menu') {
          obj[header.row + 'Link'] = row[columnIndex];
          obj[header.row] = row[columnIndex]?.length !== 0 ? 'yes' : 'na';
        }
      }
    
      return obj;
    });
    
    const filterObj = {};
    filtringFields.map((element) => {
      filterObj[element.rowName] = element.value;
    })
    // console.log('testData :>> ', testData);
    dispatch(setPartnerResautrantContacts(testData, currentPage -1, filterObj));
  };

  useEffect(() => {
    if (rows.length > 0) {
      rows[0].map((row, index) => {
        let head = { id: index, head: row, selected: false };
        setHeaders((prevState) => [...prevState, head]);
      });
      handleOpenModal();
    }
  }, [rows]);
  useEffect((e) => {
    const filterObj = {};
    filtringFields.map((element) => {
      filterObj[element.rowName] = element.value;
    })

      clearTimeout(timeoutId)
      timeoutId = setTimeout(() => {
        console.log(filterObj)
        dispatch(setPartnerResautrantContacts([], 0, filterObj));

      }, 500);
    }, [filtringFields]);

  useEffect((e) => {
    const filterObj = {};
    filtringFields.map((element) => {
      filterObj[element.rowName] = element.value;
    })
    dispatch(setPartnerResautrantContacts([], currentPage-1, filterObj));
  }, [currentPage]);



  // Start audit restaurant
  const handleEditRestau = (name, row) => {
    setOpenModalEdit(true);
    dispatch(getGoogleBusinessDataByName(name));
    dispatch(getRestaurantByID({ id: row?._id }));
  };

  const saveAuditData = (data) => {
    setOpenModalEdit(false);
    dispatch(editRestaurantByID({ data }));
  };
  const handleCloseAuditData = () => {
    setOpenModalEdit(false);
  };
  // End audit restaurant

  // Start Modal Note
  const handleModalNote = (row) => {
    setSelectedRestau(row);
    setNote(row?.note);
    setOpenModalNote(true);
  };

  const handleCloseModalNote = () => {
    setOpenModalNote(false);
    setNote("");
    setSelectedRestau({});
  };

  const handleSaveNote = (id) => {
    const data = { id, note: note };

    dispatch(editRestaurantByID({ data }));
    handleCloseModalNote();
  };
  // End Modal Note

  // Start Modal Archive
  const handleModalArchive = (row) => {
    setSelectedRestau(row);
    setOpenModalArchive(true);
  };

  const handleCloseModalArchive = () => {
    setOpenModalArchive(false);
    setSelectedRestau({});
  };

  const handleSaveArchive = (id) => {
    const data = { id: id, isArchived: true };
    setOpenModalArchive(false);
    dispatch(editRestaurantByID({ data }));
  };
  // End Modal Archive

  const iconEditStyle = {
    opacity: "0",
    visibility: "hidden",
    cursor: "pointer",
    transition: "opacity 0.3s ease",
  };
  const tdStyles = {
    padding: "0 !important",
    cursor: "pointer",
    // maxWidth: "250px",
    textOverflow: "ellipsis",
    overflow: "hidden",
    "&:hover svg": {
      visibility: "visible",
      opacity: "1",
    },
  };

  return (
    <Stack spacing={6}>
      {isLoading && (
        <Backdrop sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }} open={true}>
          <CircularProgress color="inherit" />
        </Backdrop>
      )}
      <Stack direction="column" spacing={2} justifyContent="space-between" alignItems="flex-start">
        <Stack direction="row" spacing={1} alignSelf={"flex-end"}>
          <Button
            sx={{
              textTransform: "capitalize",
              alignSelf: "flex-end",
              fontSize: "17px",
              padding: "5px 15px",
            }}
            disableElevation
            size="small"
            variant="outlined"
            onClick={() => setOpenModalAdd(true)}
          >
            Ajouter
          </Button>
          <Button
            sx={{
              textTransform: "capitalize",
              alignSelf: "flex-end",
              fontSize: "17px",
              padding: "5px 15px",
            }}
            disableElevation
            size="small"
            variant="contained"
            onClick={() => fileRef.current.click()}
          >
            <input
              id="upload"
              name="upload"
              type="file"
              ref={fileRef}
              hidden
              accept=".xlsx"
              onChange={handleChange}
            />
            Importer
          </Button>
        </Stack>
        <CssTextField
          sx={{ width: { xs: "100%", md: "500px" } }}
          size="small"
          placeholder="Search for a restaurant..."
          variant="outlined"
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <SearchIcon />
              </InputAdornment>
            ),
          }}
        />
      </Stack>

      <Card border="light" className="table-wrapper table-responsive shadow-sm">
        <Card.Body className="py-0">
          <Table hover className="user-table align-items-center">
            <thead>
              <tr>
                <th className="border-bottom px-1">
                  <EditableInput
                    style={{ width: "150px" }}
                    isActive={filtringFields[0].filter}
                    icon={
                      <FilterAltIcon
                        fontSize="small"
                        sx={{ cursor: "pointer" }}
                        onClick={() => handleShowFilter(0)}
                      />
                    }
                    activeIcon={
                      <FilterAltOffIcon
                        sx={{ cursor: "pointer" }}
                        fontSize="small"
                        onClick={() => handleRemoveFilter(0)}
                      />
                    }
                    editedData={filtringFields}
                    setEditedData={setFiltringFields}
                    id={0}
                    value={"Nom du restaurant"}
                  />
                </th>
                <th className="border-bottom px-1">
                  <EditableInput
                    style={{ width: "120px" }}
                    isActive={filtringFields[1].filter}
                    icon={
                      <FilterAltIcon
                        fontSize="small"
                        sx={{ cursor: "pointer" }}
                        onClick={() => handleShowFilter(1)}
                      />
                    }
                    activeIcon={
                      <FilterAltOffIcon
                        sx={{ cursor: "pointer" }}
                        fontSize="small"
                        onClick={() => handleRemoveFilter(1)}
                      />
                    }
                    editedData={filtringFields}
                    setEditedData={setFiltringFields}
                    id={1}
                    value={"Ville"}
                  />
                </th>
                <th className="border-bottom px-1">
                  <span style={{ paddingLeft: "25px" }}>Site web</span>
                </th>
                <th className="border-bottom px-1">
                  <span style={{ paddingLeft: "25px" }}>Résérvation</span>
                </th>
                <th className="border-bottom px-1">
                  <span style={{ paddingLeft: "25px" }}>Menu</span>
                </th>
                <th className="border-bottom px-1">
                  <EditableInput
                    style={{ width: "120px" }}
                    isActive={filtringFields[2].filter}
                    icon={
                      <FilterAltIcon
                        fontSize="small"
                        sx={{ cursor: "pointer" }}
                        onClick={() => handleShowFilter(2)}
                      />
                    }
                    activeIcon={
                      <FilterAltOffIcon
                        sx={{ cursor: "pointer" }}
                        fontSize="small"
                        onClick={() => handleRemoveFilter(2)}
                      />
                    }
                    editedData={filtringFields}
                    setEditedData={setFiltringFields}
                    id={2}
                    value={"Owner"}
                  />
                </th>
                <th className="border-bottom px-1">
                  <EditableInput
                    style={{ width: "120px" }}
                    isActive={filtringFields[3].filter}
                    icon={
                      <FilterAltIcon
                        fontSize="small"
                        sx={{ cursor: "pointer" }}
                        onClick={() => handleShowFilter(3)}
                      />
                    }
                    activeIcon={
                      <FilterAltOffIcon
                        sx={{ cursor: "pointer" }}
                        fontSize="small"
                        onClick={() => handleRemoveFilter(3)}
                      />
                    }
                    editedData={filtringFields}
                    setEditedData={setFiltringFields}
                    id={3}
                    value={"Téléphone"}
                  />
                </th>
                <th className="border-bottom px-1">
                  <span style={{ paddingLeft: "25px" }}>Email</span>
                </th>
                <th className="border-bottom px-1">
                  <span style={{ paddingLeft: "25px" }}>Actions</span>
                </th>
              </tr>
            </thead>
            <tbody>
              {contacts
                // .filter(row => {
                //   if (row?.nomRestaurant)
                //     return row?.nomRestaurant?.toLowerCase()?.includes(filtringFields[0]?.value?.toLowerCase())
                //   return true
                // })
                // .filter(row => {
                //   if (row?.city)
                //     return row?.city?.toLowerCase()?.includes(filtringFields[1]?.value?.toLowerCase())
                //   return true
                // })
                // .filter(row => {
                //   if (row?.Owner)
                //     return row?.Owner?.toLowerCase()?.includes(filtringFields[2]?.value?.toLowerCase())
                //   return true
                // })
                // .filter(row => {
                //   if (row?.phone)
                //     return row?.phone?.toLowerCase()?.includes(filtringFields[3]?.value?.toLowerCase())
                //   return true
                // })
                .map((row, id) => {
                  const {
                    Booking,
                    BookingLink,
                    Menu,
                    MenuLink,
                    city,
                    nomRestaurant,
                    siteWeb,
                    Owner,
                    phone,
                    email,
                    _id,
                  } = row;
                  return (
                    <tr key={id}>
                      <Box component="td" sx={tdStyles}>
                        <EditableInput
                          style={{ width: "200px" }}
                          isActive={showEditFieldID === 0 && selectedRowId === row._id}
                          icon={
                            <CreateIcon
                              fontSize="small"
                              sx={iconEditStyle}
                              onClick={() => handleShowEditField(0, row)}
                            />
                          }
                          activeIcon={
                            <CheckIcon
                              fontSize="medium"
                              onClick={() => handleSaveEditedData(row)}
                            />
                          }
                          editedData={editedData}
                          setEditedData={setEditedData}
                          id={0}
                          value={nomRestaurant}
                        />
                      </Box>
                      <Box component="td" sx={tdStyles}>
                        <EditableInput
                          style={{ width: "140px" }}
                          isActive={showEditFieldID === 1 && selectedRowId === row._id}
                          icon={
                            <CreateIcon
                              fontSize="small"
                              sx={iconEditStyle}
                              onClick={() => handleShowEditField(1, row)}
                            />
                          }
                          activeIcon={
                            <CheckIcon
                              fontSize="medium"
                              onClick={() => handleSaveEditedData(row)}
                            />
                          }
                          editedData={editedData}
                          setEditedData={setEditedData}
                          id={1}
                          value={city}
                        />
                      </Box>
                      <Box component="td" sx={tdStyles}>
                        <EditableInput
                          style={{ width: "140px" }}
                          isActive={showEditFieldID === 2 && selectedRowId === row._id}
                          icon={
                            <CreateIcon
                              fontSize="small"
                              sx={iconEditStyle}
                              onClick={() => handleShowEditField(2, row)}
                            />
                          }
                          activeIcon={
                            <CheckIcon
                              fontSize="medium"
                              onClick={() => handleSaveEditedData(row)}
                            />
                          }
                          editedData={editedData}
                          setEditedData={setEditedData}
                          id={2}
                          isLink={true}
                          iconLink={
                            row.siteWeb?.length !== 0 ? (
                              <IconButton href={siteWeb} target="_blank" rel="noopener noreferrer">
                                <PublicIcon />
                              </IconButton>
                            ) : (
                              <span style={{ paddingLeft: "10px" }}>No</span>
                            )
                          }
                          value={siteWeb}
                        />
                      </Box>
                      <Box component="td" sx={tdStyles}>
                        <EditableInput
                          style={{ width: "140px" }}
                          isActive={showEditFieldID === 3 && selectedRowId === row._id}
                          icon={
                            <CreateIcon
                              fontSize="small"
                              sx={iconEditStyle}
                              onClick={() => handleShowEditField(3, row)}
                            />
                          }
                          activeIcon={
                            <CheckIcon
                              fontSize="medium"
                              onClick={() => handleSaveEditedData(row)}
                            />
                          }
                          editedData={editedData}
                          setEditedData={setEditedData}
                          id={3}
                          isLink={true}
                          iconLink={
                            Booking === "yes" ? (
                              <IconButton
                                href={BookingLink}
                                target="_blank"
                                rel="noopener noreferrer"
                              >
                                <EventAvailableIcon />
                              </IconButton>
                            ) : (
                              <span style={{ paddingLeft: "10px" }}>No</span>
                            )
                          }
                          value={Booking}
                        />
                      </Box>
                      <Box component="td" sx={tdStyles}>
                        <EditableInput
                          style={{ width: "140px" }}
                          isActive={showEditFieldID === 4 && selectedRowId === row._id}
                          icon={
                            <CreateIcon
                              fontSize="small"
                              sx={iconEditStyle}
                              onClick={() => handleShowEditField(4, row)}
                            />
                          }
                          activeIcon={
                            <CheckIcon
                              fontSize="medium"
                              onClick={() => handleSaveEditedData(row)}
                            />
                          }
                          editedData={editedData}
                          setEditedData={setEditedData}
                          id={4}
                          isLink={true}
                          iconLink={
                            Menu === "yes" ? (
                              <IconButton href={MenuLink} target="_blank" rel="noopener noreferrer">
                                <RestaurantMenuIcon />
                              </IconButton>
                            ) : (
                              <span style={{ paddingLeft: "10px" }}>No</span>
                            )
                          }
                          value={Menu}
                        />
                      </Box>
                      <Box component="td" sx={tdStyles}>
                        <EditableInput
                          style={{ width: "140px" }}
                          isActive={showEditFieldID === 5 && selectedRowId === row._id}
                          icon={
                            <CreateIcon
                              fontSize="small"
                              sx={iconEditStyle}
                              onClick={() => handleShowEditField(5, row)}
                            />
                          }
                          activeIcon={
                            <CheckIcon
                              fontSize="medium"
                              onClick={() => handleSaveEditedData(row)}
                            />
                          }
                          editedData={editedData}
                          setEditedData={setEditedData}
                          id={5}
                          value={Owner}
                        />
                      </Box>
                      <Box component="td" sx={tdStyles}>
                        <EditableInput
                          style={{ width: "140px" }}
                          isActive={showEditFieldID === 6 && selectedRowId === row._id}
                          icon={
                            <CreateIcon
                              fontSize="small"
                              sx={iconEditStyle}
                              onClick={() => handleShowEditField(6, row)}
                            />
                          }
                          activeIcon={
                            <CheckIcon
                              fontSize="medium"
                              onClick={() => handleSaveEditedData(row)}
                            />
                          }
                          editedData={editedData}
                          setEditedData={setEditedData}
                          id={6}
                          value={phone}
                        />
                      </Box>
                      <Box component="td" sx={tdStyles}>
                        <EditableInput
                          style={{ width: "140px" }}
                          isActive={showEditFieldID === 7 && selectedRowId === row._id}
                          icon={
                            <CreateIcon
                              fontSize="small"
                              sx={iconEditStyle}
                              onClick={() => handleShowEditField(7, row)}
                            />
                          }
                          activeIcon={
                            <CheckIcon
                              fontSize="medium"
                              onClick={() => handleSaveEditedData(row)}
                            />
                          }
                          editedData={editedData}
                          setEditedData={setEditedData}
                          id={7}
                          value={email}
                        />
                      </Box>
                      <td
                        style={{
                          maxWidth: "250px",
                          textOverflow: "ellipsis",
                          overflow: "hidden",
                          textAlign: "center",
                        }}
                      >
                        <IconButton
                          sx={{ cursor: "pointer", textAlign: "center", padding: "5px" }}
                          onClick={(e) => {
                            handleEditRestau(nomRestaurant + " " + city, row);
                          }}
                        >
                          <VisibilityIcon />
                        </IconButton>

                        <IconButton
                          sx={{ cursor: "pointer", textAlign: "center", padding: "5px" }}
                          onClick={(e) => {
                            handleModalNote(row);
                          }}
                        >
                          <NotesIcon />
                        </IconButton>

                        <IconButton
                          sx={{ cursor: "pointer", textAlign: "center", padding: "5px" }}
                          onClick={(e) => {
                            handleModalArchive(row)
                          }}
                        >
                          <DeleteIcon />
                        </IconButton>
                      </td>
                    </tr>
                  );
                })}
            </tbody>
          </Table>
          {isLoading && (
            <div
              style={{
                width: "100%",
                display: "flex",
                flexDirection: "row",
                justifyContent: "center",
                alignItems: "center",
                padding: 10,
              }}
            >
              <CircularProgress size={25} color="error" />
            </div>
          )}
          {pageCount > 1 && (
            <Card.Footer className="mt-7 pb-3 border-0 d-lg-flex align-items-center justify-content-center">
              <Nav className="justify-content-center">
                <Pagination className="mb-2 mb-lg-0">
                  <Pagination.Prev
                    onClick={() => {
                      if (currentPage > 1) setCurrentPage((e) => e - 1);
                    }}
                  >
                    Previous
                  </Pagination.Prev>
                  {[
                    ...new Set([
                      1,
                      2,
                      ".",
                      ...[2, 1, 0, -1, -2]
                        .map((e) => currentPage - e)
                        .filter((e) => e < pageCount - 2)
                        .filter((e) => e > 0),
                      "..",
                      ...[2, 1].map((e) => {
                        return pageCount - e;
                      }),
                    ]),
                  ]
                    .filter((e) => e < pageCount || e == "." || e == "..")
                    .map((e) => {
                      if (
                        (e == "." && currentPage >= 6) ||
                        (e == ".." && currentPage <= pageCount - 6)
                      )
                        return <Pagination.Next>...</Pagination.Next>;
                      if (e != "." && e != "..")
                        return (
                          <Pagination.Item
                            onClick={() => setCurrentPage(e)}
                            active={e == currentPage}
                          >
                            {e}
                          </Pagination.Item>
                        );
                    })}
                  <Pagination.Next
                    onClick={() => {
                      if (currentPage < pageCount - 1) setCurrentPage((e) => e + 1);
                    }}
                  >
                    Next
                  </Pagination.Next>
                </Pagination>
              </Nav>
            </Card.Footer>
          )}
        </Card.Body>
      </Card>
      <ModalImportRestau
        headers={headers}
        handleChangeHeader={handleChangeHeader}
        open={openModal}
        handleClose={handleCloseModal}
        tableRows={tableRows}
        handleSaveButton={handleSaveButton}
      />

      <ModalAddRestau
        open={openModalAdd}
        data={addOneData}
        handleChange={handleChangeData}
        handleClose={handleCloseMadalAdd}
        handleSaveButton={handleSaveAddOne}
      />

      <ModalEditRestau
        open={openModalEdit}
        handleClose={handleCloseAuditData}
        handleSave={saveAuditData}
      />

      <ModalNote
        open={openModalNote}
        data={selectedRestau}
        handleClose={handleCloseModalNote}
        note={note}
        setNote={setNote}
        handleSave={handleSaveNote}
      />

      <ModalArchiveRestau
        open={openModalArchive}
        row={selectedRestau}
        handleClose={handleCloseModalArchive}
        handleSave={handleSaveArchive}
      />
    </Stack>
  );
};

export default MyRestau;
