import React, { useEffect } from 'react'
import { Backdrop, Box, Button, CircularProgress, FormControl, IconButton, InputAdornment, Autocomplete, MenuItem, Select, Stack, TextField, styled } from '@mui/material'
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSearch } from "@fortawesome/free-solid-svg-icons";
import { Nav, Card, Table, Pagination } from "@themesberg/react-bootstrap";
import { useState } from 'react';
import ModalImportRestau from './components/MyRestau/ModalImportRestau';
import ModalAddContact from './components/contacts/ModalAddContact';
import { useRef } from 'react';
import readXlsxFile from 'read-excel-file'
import { useDispatch, useSelector } from 'react-redux';
import { editContactByField, setContactToRestaurant, setPartnerContacts } from '../redux/partnerContact/actions';
import {setPartnerResautrantContacts} from "../redux/partnerRestaurantContact/actions"
import EditableInput from './components/EditableInput';
import FilterAltIcon from '@mui/icons-material/FilterAlt';
import FilterAltOffIcon from '@mui/icons-material/FilterAltOff';
import CreateIcon from "@mui/icons-material/Create";
import CheckIcon from '@mui/icons-material/Check';
import SyncAltIcon from '@mui/icons-material/SyncAlt';

const CssTextField = styled(TextField)({
  '& .MuiOutlinedInput-root': {
    borderRadius: "8px",
    overflow: "hidden",
    backgroundColor: "#fff",
    '& fieldset': {
      borderColor: '#D1D7E0',
      borderWidth: "1px",
    },
    '&:hover fieldset': {
      borderColor: '#D1D7E0',
      borderWidth: "1px"
    },
    '&.Mui-focused fieldset': {
      borderColor: '#D1D7E0',
      borderWidth: "1px"
    },
  },
});

const Contacts = () => {
  const [openModal, setOpenModal] = useState(false);
  const [openModalAdd, setOpenModalAdd] = useState(false);
  const [file, setFile] = useState();
  const [rows, setRows] = useState([]);
  const [DataRows, setDataRows] = useState([]);
  const [headers, setHeaders] = useState([]);
  const dispatch = useDispatch()
  const [mappedHeaders, setMappedHeaders] = useState([
    { id: 0, row: "Nom", value: null },
    { id: 1, row: "Prenom", value: null },
    { id: 2, row: "Email", value: null },
    { id: 3, row: "Téléphone", value: null },
    { id: 4, row: "Poste", value: null }
  ]);
  const [fillTable, setFillTable] = useState(false)
  const { contacts, pageCount, isLoading } = useSelector(state => state.partnerContact)
  const { contacts: restaurant } = useSelector(state => state.PartnerRestaurantContact)
  let tableRows = [
    { id: 0, rowName: "Nom" },
    { id: 1, rowName: "Prénom" },
    { id: 2, rowName: "Email" },
    { id: 3, rowName: "Téléphone" },
    { id: 4, rowName: "Poste" },
  ];
  const [filtringFields, setFiltringFields] = useState([
    { id: 0, filter: false, value: "" },
    { id: 1, filter: false, value: "" },
    { id: 2, filter: false, value: "" },
    { id: 3, filter: false, value: "" },
    { id: 4, filter: false, value: "" },
    { id: 5, filter: false, value: "" },
  ])

  const [filterRestaurant, setFilterRestaurant] = useState({nomRestaurant : ""})
  const [showEditFieldID, setShowEditFieldID] = useState(-1);
  const [selectedRowId, setSelectedRowId] = useState(-1);
  const [editedData, setEditedData] = useState([
    { id: 0, name: "nom", value: "" },
    { id: 1, name: "prenom", value: "" },
    { id: 2, name: "email", value: "" },
    { id: 3, name: "phone", value: "" },
    { id: 4, name: "job", value: "" },
  ])
  const [currentPage, setCurrentPage] = useState(1)
  const fileRef = useRef();

  const [addOneData, setAddOneData] = useState({
    name: "",
    prenom: "",
    email: "",
    phone: "",
    restaurant: "", 
    job : ""
  })

  const handleChangeData = (itemHeader, value) => {
    setAddOneData({ ...addOneData, [itemHeader]: value })
  }

  const handleCloseModalAddContact = () => {
    setOpenModalAdd(false);
    setAddOneData({
      name: "",
      prenom: "",
      email: "",
      phone: "",
      restaurant: "", 
      job :""
    })
  }

  const handleSaveAddOne = () => {
    handleCloseModalAddContact();
    setFillTable(true);
    let data = [];
    for (const item in addOneData) {
      switch (item) {
        case "name":
          data.push({ id: 0, value: addOneData[item] });
          break;
        case "prenom":
          data.push({ id: 1, value: addOneData[item] });
          break;
        case "email":
          data.push({ id: 2, value: addOneData[item] });
          break;
        case "phone":
          data.push({ id: 3, value: addOneData[item] });
          break;
        case "job":
            data.push({id : 4, value : addOneData[item]})
          break;
        default:
          continue
      }
    }
    dispatch(setPartnerContacts([data], currentPage));
  }

  // Start Filter 
  const handleShowFilter = (id) => {
    setFiltringFields(filtringFields.map(el => el.id === id ? { ...filtringFields[id], filter: true } : el))
  }

  const handleRemoveFilter = (id) => {
    setFiltringFields(filtringFields.map(el => el.id === id ? { ...filtringFields[id], filter: false, value: "" } : el))
  }
  // End Filter

  // Start edit fields 
  const handleShowEditField = (id, row) => {
    setEditedData([
      { id: 0, name: "nom", value: row.nom },
      { id: 1, name: "prenom", value: row.prenom },
      { id: 2, name: "email", value: row.email },
      { id: 3, name: "phone", value: row.phone },
      { id: 4, name: "job", value: row.job },
    ])

    setShowEditFieldID(id);
    setSelectedRowId(row._id);
  }

  const handleSaveEditedData = (id, row, currentPage) => {
    dispatch(editContactByField({ rowID: row?._id, ...editedData[id], currentPage }))
    setShowEditFieldID(-1);
  }
  // End edit fields

  const handleChange = (event) => {
    setFile(event.target.files[0]);
    readXlsxFile(event.target.files[0]).then(allRows => {
      setRows(allRows);

    })
  };

  const handleOpenModal = () => {
    setOpenModal(true);
  }
  const handleCloseModal = () => {
    setOpenModal(false);
  }

  const handleChangeHeader = (name, id) => {

    setHeaders([...headers.map(header => {
      if (header.head === name) {
        return { ...header, selected: true }
      }
      return header;
    })])
    setMappedHeaders([...mappedHeaders.map(item => {
      if (item.id === id) {
        return { ...item, value: name }
      }
      return item;
    })])



  }

  const handleSaveButton = () => {
    setOpenModal(false);
    setFillTable(true);
    const data = rows.slice(1).map((row, id) => {
      return mappedHeaders.map((el, index) => {
        return { value: row[rows[0].findIndex((i) => i == el.value)], id: el.id }
      })
    })
    dispatch(setPartnerContacts(data, currentPage - 1))

  }
  useEffect((e) => {
    dispatch(setPartnerContacts([], currentPage - 1))
  }, [])

  useEffect((e) => {
    dispatch(setPartnerContacts([], currentPage - 1));
  }, [currentPage]);

  useEffect(() => {
    if (rows.length > 0) {
      rows[0].map((row, index) => {
        let head = { id: index, head: row, selected: false };
        setHeaders((prevState) => [...prevState, head])
      })
      handleOpenModal()
    }
  }, [rows])

  const iconEditStyle = {
    opacity: "0",
    visibility: "hidden",
    cursor: "pointer",
    transition: "opacity 0.3s ease",
  };
  const tdStyles = {
    width: "17%",
    padding: "0 4px !important",
    cursor: "pointer",
    maxWidth: "250px",
    textOverflow: "ellipsis",
    overflow: "hidden",
    "&:hover svg": {
      visibility: "visible",
      opacity: "1",
    },
  };

  let timeoutId = null
  const hanldeSearchForRestau = (e) => {
    e.persist();
    
    setFilterRestaurant((prevState) => {return {...prevState, "nomRestaurant" :  e.target.value}});
    // clearTimeout(timeoutId)
    // timeoutId = setTimeout(() => {
      
    // }, 1000);
  };


  useEffect(() => {
    dispatch(setPartnerResautrantContacts([], 0, filterRestaurant));
  }, [filterRestaurant])

  return (
    <Stack spacing={6}>
      {isLoading &&
        <Backdrop
          sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
          open={true}
        >
          <CircularProgress color="inherit" />
        </Backdrop>
      }
      <Stack direction="column" spacing={2} justifyContent="space-between" alignItems="flex-start">
        <Stack direction="row" spacing={1} alignSelf={"flex-end"}>
          <Button sx={{
            textTransform: "capitalize",
            alignSelf: "flex-end",
            fontSize: "17px",
            padding: "5px 15px"
          }}
            disableElevation
            size="small"
            variant="outlined"
            onClick={() => setOpenModalAdd(true)}
          >
            Ajouter
          </Button>
          <Button
            sx={{
              textTransform: "capitalize",
              alignSelf: "flex-end",
              fontSize: "17px",
              padding: "5px 15px"
            }}
            disableElevation
            size="small"
            variant="contained"
            onClick={() => fileRef.current.click()}>
            <input
              id="upload"
              name="upload"
              type="file"
              ref={fileRef}
              hidden
              accept=".xlsx"
              onChange={handleChange} />
            Importer
          </Button>
        </Stack>

        <CssTextField sx={{ width: { xs: "100%", md: "500px" } }}
          size="small"
          placeholder="Search for a contact..."
          variant="outlined"
          InputProps={{
            startAdornment: <InputAdornment position="start"><FontAwesomeIcon icon={faSearch} /></InputAdornment>,
          }}
        />
      </Stack>

      <Card border="light" className="table-wrapper table-responsive shadow-sm">
        <Card.Body className="py-0">
          <Table hover className="user-table align-items-center">
            <thead>
              <tr>
                <th className="border-bottom px-1">
                  <EditableInput
                    isActive={filtringFields[0].filter}
                    icon={<FilterAltIcon fontSize="small" sx={{ cursor: "pointer" }} onClick={() => handleShowFilter(0)}
                    />}
                    activeIcon={<FilterAltOffIcon sx={{ cursor: "pointer" }} fontSize="small" onClick={() => handleRemoveFilter(0)} />}
                    editedData={filtringFields}
                    setEditedData={setFiltringFields}
                    id={0}
                    value={"Nom"} />
                </th>
                <th className="border-bottom px-1">
                  <EditableInput
                    isActive={filtringFields[1].filter}
                    icon={<FilterAltIcon fontSize="small" sx={{ cursor: "pointer" }} onClick={() => handleShowFilter(1)}
                    />}
                    activeIcon={<FilterAltOffIcon sx={{ cursor: "pointer" }} fontSize="small" onClick={() => handleRemoveFilter(1)} />}
                    editedData={filtringFields}
                    setEditedData={setFiltringFields}
                    id={1}
                    value={"Prénom"} />
                </th>
                <th className="border-bottom px-1">
                  <EditableInput
                    isActive={filtringFields[2].filter}
                    icon={<FilterAltIcon fontSize="small" sx={{ cursor: "pointer" }} onClick={() => handleShowFilter(2)}
                    />}
                    activeIcon={<FilterAltOffIcon sx={{ cursor: "pointer" }} fontSize="small" onClick={() => handleRemoveFilter(2)} />}
                    editedData={filtringFields}
                    setEditedData={setFiltringFields}
                    id={2}
                    value={"Email"} />
                </th>
                <th className="border-bottom px-1">
                  <EditableInput
                    isActive={filtringFields[3].filter}
                    icon={<FilterAltIcon fontSize="small" sx={{ cursor: "pointer" }} onClick={() => handleShowFilter(3)}
                    />}
                    activeIcon={<FilterAltOffIcon sx={{ cursor: "pointer" }} fontSize="small" onClick={() => handleRemoveFilter(3)} />}
                    editedData={filtringFields}
                    setEditedData={setFiltringFields}
                    id={3}
                    value={"Téléphone"} />
                </th>
                <th className="border-bottom px-1">
                  <EditableInput
                    isActive={filtringFields[4].filter}
                    icon={<FilterAltIcon fontSize="small" sx={{ cursor: "pointer" }}
                      onClick={() => handleShowFilter(4)}
                    />}
                    activeIcon={<FilterAltOffIcon sx={{ cursor: "pointer" }} fontSize="small"
                      onClick={() => handleRemoveFilter(4)} />}
                    editedData={filtringFields}
                    setEditedData={setFiltringFields}
                    id={4}
                    value={"Restaurant"} />
                </th>
                <th className="border-bottom px-1">
                  <EditableInput
                    isActive={filtringFields[5].filter}
                    icon={<FilterAltIcon fontSize="small" sx={{ cursor: "pointer" }} onClick={() => handleShowFilter(5)}
                    />}
                    activeIcon={<FilterAltOffIcon sx={{ cursor: "pointer" }} fontSize="small" onClick={() => handleRemoveFilter(5)} />}
                    editedData={filtringFields}
                    setEditedData={setFiltringFields}
                    id={5}
                    value={"Poste"} />
                </th>
                <th className="border-bottom px-1">Actions</th>
              </tr>
            </thead>

            <tbody>
              {contacts
                .filter(row => {
                  if (row?.nom)
                    return row?.nom?.toLowerCase()?.includes(filtringFields[0]?.value?.toLowerCase())
                  return true
                })
                .filter(row => {
                  if (row?.prenom)
                    return row?.prenom?.toLowerCase()?.includes(filtringFields[0]?.value?.toLowerCase())
                  return true
                })
                .filter(row => {
                  if (row?.email)
                    return row?.email?.toLowerCase()?.includes(filtringFields[0]?.value?.toLowerCase())
                  return true
                })
                .filter(row => {
                  if (row?.phone)
                    return row?.phone?.toLowerCase()?.includes(filtringFields[0]?.value?.toLowerCase())
                  return true
                }).filter(row => {
                  if (row?.job)
                    return row?.job?.toLowerCase()?.includes(filtringFields[0]?.value?.toLowerCase())
                  return true
                })
                // .filter(row => {
                //   return row.nom?.includes(filtringFields[0]?.value) &&
                //     row.prenom?.includes(filtringFields[1]?.value) &&
                //     row.email?.includes(filtringFields[2]?.value) &&
                //     row.phone?.includes(filtringFields[3]?.value)
                // })
                .map((row, id) => {
                  return (
                    <tr key={id}>
                      <Box component="td" sx={tdStyles}>
                        <EditableInput
                          isActive={showEditFieldID === 0 && selectedRowId === row._id}
                          icon={<CreateIcon fontSize="small" sx={iconEditStyle} onClick={() => handleShowEditField(0, row)}
                          />}
                          activeIcon={<CheckIcon fontSize="medium" onClick={() => handleSaveEditedData(0, row, currentPage)} />}
                          editedData={editedData}
                          setEditedData={setEditedData}
                          id={0}
                          value={row.nom} />
                      </Box>
                      <Box component="td" sx={tdStyles}>
                        <EditableInput
                          isActive={showEditFieldID === 1 && selectedRowId === row._id}
                          icon={<CreateIcon fontSize="small" sx={iconEditStyle} onClick={() => handleShowEditField(1, row)}
                          />}
                          activeIcon={<CheckIcon fontSize="medium" onClick={() => handleSaveEditedData(1, row, currentPage)} />}
                          editedData={editedData}
                          setEditedData={setEditedData}
                          id={1}
                          value={row?.prenom} />
                      </Box>
                      <Box component="td" sx={tdStyles}>
                        <EditableInput
                          type="email"
                          isActive={showEditFieldID === 2 && selectedRowId === row._id}
                          icon={<CreateIcon fontSize="small" sx={iconEditStyle} onClick={() => handleShowEditField(2, row)}
                          />}
                          activeIcon={<CheckIcon fontSize="medium" onClick={() => handleSaveEditedData(2, row, currentPage)} />}
                          editedData={editedData}
                          setEditedData={setEditedData}
                          id={2}
                          value={row.email} />
                      </Box>
                      <Box component="td" sx={tdStyles}>
                        <EditableInput
                          type="tel"
                          isActive={showEditFieldID === 3 && selectedRowId === row._id}
                          icon={<CreateIcon fontSize="small" sx={iconEditStyle} onClick={() => handleShowEditField(3, row)}
                          />}
                          activeIcon={<CheckIcon fontSize="medium" onClick={() => handleSaveEditedData(3, row, currentPage)} />}
                          editedData={editedData}
                          setEditedData={setEditedData}
                          id={3}
                          value={row.phone} />
                      </Box>
                      <Box component="td" sx={tdStyles}>
                        <Autocomplete
                          filterOptions={(options, state) => options}
                          getOptionLabel={(option) => {
                            return option.nomRestaurant
                          }}
                          onChange={(event, newValue) => dispatch(setContactToRestaurant({ restaurant_id: newValue._id, contact_id: row._id }))}
                          options={restaurant}
                          size='small'
                          fullWidth
                          defaultValue={restaurant.find(el => el._id === row?.partnerRestaurant?._id)}
                          sx={{
                            marginLeft: "25px",
                            height: 31,
                            width: "180px"
                          }}
                          renderInput={(params) => <TextField {...params} 
                            label="Restaurant" 
                            size = "small" 
                            onChange={(e) => hanldeSearchForRestau(e)}  />}
                        />
                        {/* <Select
                          onChange={(event) => dispatch(setContactToRestaurant({ restaurant_id: event.target.value, contact_id: row._id }))}
                          defaultValue={row?.partnerRestaurant?._id || ""}
                          size="small"
                          fullWidth
                          style={{
                            marginLeft: "25px",
                            height: 31,
                            width: "180px"
                          }}
                        >
                          {
                            restaurant?.map((header) => {
                              return (
                                <MenuItem key={header._id} value={header._id}>{header.nomRestaurant}</MenuItem>
                              )
                            })
                          }
                        </Select> */}
                      </Box>
                      <Box component="td" sx={tdStyles}>
                        <EditableInput
                          type="text"
                          isActive={showEditFieldID === 4 && selectedRowId === row._id}
                          icon={<CreateIcon fontSize="small" sx={iconEditStyle} onClick={() => handleShowEditField(4, row)}
                          />}
                          activeIcon={<CheckIcon fontSize="medium" onClick={() => handleSaveEditedData(4, row, currentPage)} />}
                          editedData={editedData}
                          setEditedData={setEditedData}
                          id={4}
                          value={row.job} />
                      </Box>
                      <td style={{ width: "120px", textOverflow: "ellipsis", overflow: "hidden" }}>
                        <IconButton
                          size="medium"
                          aria-label="edit-restaurant"
                          onClick={(e) => {
                            // handleEditRestau(nomRestaurant+" "+city);
                            // console.log(filterData)
                          }}
                        >
                          <SyncAltIcon fontSize="inherit" />
                        </IconButton>
                      </td>
                    </tr>
                  )
                })
              }
            </tbody>
          </Table>
          {pageCount > 1 &&
            <Card.Footer className="mt-7 pb-3 border-0 d-lg-flex align-items-center justify-content-center">
              <Nav className="justify-content-center">
                <Pagination className="mb-2 mb-lg-0">
                  <Pagination.Prev onClick={() => {
                    if (currentPage > 1)
                      setCurrentPage(e => e - 1)
                  }}>Previous</Pagination.Prev>
                  {[...new Set([1, 2, ".", ...[2, 1, 0, -1, - 2]
                    .map((e) => currentPage - e)
                    .filter((e) => e < pageCount - 2)
                    .filter((e) => e > 0), "..", ...[2, 1]
                      .map((e) => { return pageCount - e })])]
                    .filter(e => e < pageCount || e == "." || e == "..")
                    .map((e) => {
                      if ((e == "." && currentPage >= 6) || (e == ".." && currentPage <= pageCount - 6))
                        return <Pagination.Next>...</Pagination.Next>
                      if (e != "." && e != "..")
                        return (<Pagination.Item onClick={() => setCurrentPage(e)} active={e == currentPage}>{e}</Pagination.Item>)
                    })}
                  <Pagination.Next onClick={() => {
                    if (currentPage < pageCount - 1)
                      setCurrentPage((e) => e + 1)
                  }}>Next</Pagination.Next>
                </Pagination>
              </Nav>
            </Card.Footer>}
        </Card.Body>
      </Card>
      <ModalImportRestau
        headers={headers}
        handleChangeHeader={handleChangeHeader}
        open={openModal}
        handleClose={handleCloseModal}
        tableRows={tableRows}
        handleSaveButton={handleSaveButton}
      />

      <ModalAddContact
        open={openModalAdd}
        data={addOneData}
        handleChange={handleChangeData}
        handleClose={handleCloseModalAddContact}
        handleSaveButton={handleSaveAddOne}
      />
    </Stack >
  )
}

export default Contacts
