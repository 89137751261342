import React from "react";
import { Col, Row, } from "@themesberg/react-bootstrap";
import { ChoosePhotoWidget, ProfileCardWidget } from "../components/Widgets";
import { GeneralInfoForm } from "../components/Forms";

import Profile1 from "../assets/img/team/user.jpg";

export default () => {
  return (
    <>
      <Row>
        <Col xs={12} xl={12}>
          <GeneralInfoForm />
        </Col>

        {/* <Col xs={12} xl={4}>
          <Row>
            <Col xs={12}>
              <ProfileCardWidget />
            </Col>
            <Col xs={12}>
              <ChoosePhotoWidget
                title="Select profile photo"
                photo={Profile1}
              />
            </Col>
          </Row>
        </Col> */}
      </Row>
    </>
  );
};
