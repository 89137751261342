import { httpClient_get, httpClient_post } from '../../helpers/api';

const getClientService = (email, password) => {
    return httpClient_post('/partners/client', {
        email: email,
        password: password,
    })
        .then((response) => {
            return response.data.client;
        })
        .catch((error) => {
            console.log('-----error------', error.response);
            throw error.response.data;
        });
};
export {
    getClientService,
};