
import { all, call, fork, put, takeEvery } from 'redux-saga/effects';
import { getGoogleBusinessByNameService, getGoogleBusinessService , getGoogleBusinessServiceByName } from './service';
import { getGoogleBusinessData, getGoogleBusinessDataByNameFailed, getGoogleBusinessDataByNameSuccess, getGoogleBusinessDataFailed, getGoogleBusinessDataSuccess } from './actions';
import { GET_GOOGLE_BUSINESS_DATA, GET_GOOGLE_BUSINESS_DATA_BY_NAME } from '../constants/actionTypes';


function* getGoogleBusinessDataSaga({ payload }) {
  try {
    const response = yield call(getGoogleBusinessService, payload);
    yield put(getGoogleBusinessDataSuccess(response));
  } catch (error) {
    yield put(getGoogleBusinessDataFailed(error.message));
  }
}

function* getGoogleBusinessDataByNameSaga({ payload }) {
  try {
    const response = yield call(getGoogleBusinessServiceByName, payload);
    console.log('response', response)
    yield put(getGoogleBusinessDataByNameSuccess(response));
  } catch (error) {
    yield put(getGoogleBusinessDataFailed(error.message));
  }
}



export function* watchGoogleBusinessData() {
  yield takeEvery(GET_GOOGLE_BUSINESS_DATA, getGoogleBusinessDataSaga);
}
export function* watchGoogleBusinessDataByName() {
  yield takeEvery(GET_GOOGLE_BUSINESS_DATA_BY_NAME, getGoogleBusinessDataByNameSaga);
}

function* googleBusinessSaga() {
  yield all([
    watchGoogleBusinessData(),
    watchGoogleBusinessDataByName()
  ]);
}

export default googleBusinessSaga;