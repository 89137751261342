import {
  EDIT_PARTNER_SERVICE_BY_ID,
  EDIT_PARTNER_SERVICE_BY_ID_FAILED,
  EDIT_PARTNER_SERVICE_BY_ID_SUCCESS,
  GET_PARTNER_SERVICES,
  GET_PARTNER_SERVICES_FAILED,
  GET_PARTNER_SERVICES_SUCCESS,
  SET_PARTNER_SERVICES,
  SET_PARTNER_SERVICES_FAILED,
  SET_PARTNER_SERVICES_SUCCESS,
} from "../constants/actionTypes";

export const setPartnerServices = (data) => ({
  type: SET_PARTNER_SERVICES,
  payload: data,
});

export const setPartnerServicesSuccess = (data) => ({
  type: SET_PARTNER_SERVICES_SUCCESS,
  payload: data,
});

export const setPartnerServicesFailed = (error) => ({
  type: SET_PARTNER_SERVICES_FAILED,
  payload: error,
});

export const getPartnerServices = (data) => ({
  type: GET_PARTNER_SERVICES,
  payload: data,
});

export const getPartnerServicesSuccess = (data) => ({
  type: GET_PARTNER_SERVICES_SUCCESS,
  payload: data,
});

export const getPartnerServicesFailed = (error) => ({
  type: GET_PARTNER_SERVICES_FAILED,
  payload: error,
});

export const editPartnerServiceByID = (data) => ({
  type: EDIT_PARTNER_SERVICE_BY_ID,
  payload: data,
});

export const editPartnerServiceByIDSuccess = (data) => ({
  type: EDIT_PARTNER_SERVICE_BY_ID_SUCCESS,
  payload: data,
});

export const editPartnerServiceByIDFailed = (error) => ({
  type: EDIT_PARTNER_SERVICE_BY_ID_FAILED,
  payload: error,
});
