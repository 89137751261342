import { Box, Button, FormControl, InputLabel, MenuItem, Modal, Select, Stack, TextField, Typography } from '@mui/material'
import React from 'react'
import ArrowRightAltIcon from '@mui/icons-material/ArrowRightAlt';


const ModalImportRestau = ({open, handleClose, headers, handleChangeHeader, tableRows, handleSaveButton}) => {
    const boxStyle = {
        backgroundColor : "#fff",
        width : {xs : "90%", md: "650px"},
        padding : "25px",
        borderRadius : "15px",
        border : "none", 
    }

    // const handleSelectChange = (event) => {
    //     let selected = event.target.value;
    //      headers.map(el => {
    //         if(el === selected){
    //             el.selected = true;
    //         }
    //         return el;
    //     })
    //     console.log(tableRows)
    // }

    return (
        <Modal
        style = {{display :"flex", justifyContent : "center", alignItems : "center"}}
        open={open}
        onClose={handleClose}
            >
            <Box sx = {boxStyle}>
                <Stack spacing = {2} alignItems="flex-end">
                {tableRows?.map((row) => (
                    <Stack sx = {{width : "100%"}} key ={row.id} direction = "row" spacing = {2} justifyContent = "space-between" alignItems = "center">
                        <TextField sx = {{flex : "1"}} disabled value = {row.rowName} size = "small" />
                        <ArrowRightAltIcon />
                        <FormControl size = "small" sx = {{flex : "1"}}>
                            <InputLabel id="demo-simple-select-label">Rows</InputLabel>
                            <Select
                                onChange={(event) => handleChangeHeader(event.target.value, row.id)}
                                size = "small"
                                labelId="demo-simple-select-label"
                                label="Rows">
                                {
                                    headers?.map((header) => (
                                        <MenuItem key = {header.id} value={header.head}>{header.head}</MenuItem>
                                    ))
                                }
                            </Select>
                        </FormControl>
                    </Stack>
                ))}
                <Button disableElevation variant = "outlined" size ="small" onClick = {handleSaveButton}>Enregistrer</Button>
                </Stack>
            </Box>
        </Modal>
  )
}

export default ModalImportRestau
