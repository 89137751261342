// @flow
import { Cookies } from 'react-cookie';
import { all, call, fork, put, takeEvery } from 'redux-saga/effects';

import {
  LOGIN_USER,
  LOGOUT_USER,
  REGISTER_USER,
  UPDATE_USER,
} from '../constants/actionTypes';

import {
  loginUserSuccess,
  loginUserFailed,
  updateUserSuccess,
  updateUserFailed,
} from './actions';
import {
  loginService, registerService, updateService,
} from './service';

/**
 * Sets the session
 * @param {*} user
 */
const setSession = (user) => {
  let cookies = new Cookies();
  console.log({ okkkk: user })
  if (user) {
    cookies.set(
      'user',
      JSON.stringify({
        token: user.token,
        email: user.email,
        password: user.password,
        role: user.role,
      }),
      { path: '/' },
    );
    // ability.update(defineRulesFor(user));
  } else cookies.remove('user');
};


function* login({ payload: { username, password, callback } }) {

  try {
    const response = yield call(loginService, username, password);

    const user = {
      ...response.partner,
      token: response.token,
      expiresIn: response.expiresIn,
    };
    yield put(loginUserSuccess(user));
    setSession({ ...user });
    callback();
  } catch (error) {
    console.log(error)
    yield put(loginUserFailed(error.message));
    setSession(null);
  }
}

function* register({ payload: { email, password, callback = () => null } }) {

  try {
    const response = yield call(registerService, email, password);

    const user = {
      ...response.partner,
      token: response.token,
      expiresIn: response.expiresIn,
    };

    yield put(loginUserSuccess(user));
    setSession({ ...user });
    callback();
  } catch (error) {
    yield put(loginUserFailed(error.message));
    setSession(null);
  }
}


function* update({ payload: {
  frname,
  ltname,
  birthday,
  gender,
  phone,
  adresse,
  adresseNo,
  company,
  service,
  city,
  state,
  zipcode,
  callback = () => null
} }) {

  try {
    const response = yield call(updateService, frname,
      ltname,
      birthday,
      gender,
      phone,
      adresse,
      adresseNo,
      company,
      service,
      city,
      state,
      zipcode
    );

    const user = {
      ...response.partner,
    };

    yield put(updateUserSuccess(user));

  } catch (error) {
    yield put(updateUserFailed(error.message));

    setSession(null);
  }
}

function* logout({ payload: { history } }) {
  try {
    setSession(null);
    yield call(() => {
      history.push('/login');
    });
  } catch (error) { }
}



export function* watchLoginUser() {
  yield takeEvery(LOGIN_USER, login);
}

export function* watchRegisterUser() {
  yield takeEvery(REGISTER_USER, register)
}

export function* watchLogoutUser() {
  yield takeEvery(LOGOUT_USER, logout);
}


export function* watchUpdateUser() {
  yield takeEvery(UPDATE_USER, update);
}


function* authSaga() {
  yield all([
    fork(watchLoginUser),
    fork(watchLogoutUser),
    fork(watchRegisterUser),
    fork(watchUpdateUser),
  ]);
}

export default authSaga;