import {
  Box,
  Button,
  IconButton,
  Modal,
  Paper,
  Stack,
  TextField,
  Typography,
  RadioGroup,
  FormControlLabel,
  Radio,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import searchIcon from "../../../assets/img/icons/search.gif";
import { useSelector } from "react-redux";
import CloseIcon from "@mui/icons-material/Close";

const boxStyle = {
  backgroundColor: "#fff",
  padding: "25px",
  borderRadius: "15px",
  border: "none",
};

const typoStyle = {
  color: "#000",
  height: "40px",
  textOverflow: "ellipsis",
  whiteSpace: "nowrap",
  overflow: "hidden",
};

const ModalEditRestau = ({
  open,
  handleClose,
  handleSave,
}) => {
  const [googleBusinessInfo, setGoogleBusinessInfo] = useState({});
  const [ourInfo, setOurInfo] = useState({});

  const { data, isLoading } = useSelector((state) => state.GoogleBusinessReducer);
  const {restaurant} = useSelector((state)=> state.PartnerRestaurantContact) 

  const [auditData, setAuditData] = useState({
    id : "",
    nomRestaurant:  "",
    city:  "",
    siteWeb:  "",
    phone:  "",
    email:  "",
    Owner:  "",
    Booking:  "",
    BookingLink:  "",
    Menu:  "",
    MenuLink :  ""
  })

  useEffect(() => {
    if (data?.data?.status?.toLowerCase() === "ok") {
      setGoogleBusinessInfo(data?.info[0]?.result);
    }
  }, [data]);

  useEffect(() => {
    setOurInfo(restaurant?.data);
  }, [restaurant])

  useEffect(() => {
    if (googleBusinessInfo && ourInfo) {
      setAuditData((prevState) => ({
        ...prevState, 
        id : ourInfo?._id, 
        nomRestaurant : ourInfo?.nomRestaurant || googleBusinessInfo?.name, 
        city : ourInfo?.city || googleBusinessInfo?.address_components?.find((el) => el.types.includes("locality"))?.long_name, 
        phone : ourInfo?.phone || googleBusinessInfo?.formatted_phone_number,
        email : ourInfo?.email || "",
        Booking : ourInfo?.Booking || googleBusinessInfo?.reservable ? "yes" : "na", 
        BookingLink : ourInfo?.BookingLink || "",
        siteWeb:  ourInfo?.siteWeb || googleBusinessInfo?.website, 
        Owner:  ourInfo?.Owner || "",
        Menu:  ourInfo?.Menu || "na",
        MenuLink:  ourInfo?.MenuLink || "",
      }))
    }
  }, [googleBusinessInfo, ourInfo]);


  const handleChange = (name, value)=>{
    setAuditData((prevState) => ({
      ...prevState,
      [name]: value, 
    }));
  }

  const handleCloseModal = () => {
    setAuditData({ 
        id : "",
        nomRestaurant:  "",
        city:  "",
        siteWeb:  "",
        phone:  "",
        Owner:  "",
        Booking:  "",
        BookingLink:  "",
        Menu:  "",
        MenuLink :  ""
  })
  setOurInfo({})
  setGoogleBusinessInfo({})
  handleClose();
  }

  const handleSaveData = () => {
    handleSave(auditData)
  }

  return (
    <Modal
      style={{ display: "flex", justifyContent: "center", alignItems: "center" }}
      open={open}
      onClose={handleCloseModal}
    >
      <Box sx={boxStyle}>
        {/* Search for the restaurant in the API  */}
        {isLoading && (
          <Stack spacing={2} alignItems="center" sx={{ width: { xs: "90%", sm: "450px" } }}>
            <Stack
              justifyContent={"center"}
              alignItems={"center"}
              spacing={3}
              sx={{ height: "240px", width: "100%" }}
            >
              <Typography variant="h5">
                Searching for
              </Typography>
              <Box component={"img"} src={searchIcon} />
            </Stack>
          </Stack>
        )}

        {!isLoading && (
          <Stack
            direction="row"
            flexWrap={"wrap"}
            justifyContent={"center"}
            sx={{
              width: "100vw",
              height: "100vh",
              padding: { xs: "50px", md: "25px" },
              overflowY: "scroll",
              position: "relative",
              gap: "25px",
            }}
          >
            <IconButton
              onClick={handleCloseModal}
              sx={{ position: "absolute", top: "10px", right: "10px" }}
            >
              <CloseIcon />
            </IconButton>

            <Stack
              component={Paper}
              elevation={3}
              spacing={4}
              sx={{ padding: "15px", width: { xs: "100%", md: "48%", lg: "28%" } }}
            >
              <Typography variant="h5" sx={{ textAlign: "center" }}>
                Google Place
              </Typography>
              <Stack spacing={2} sx={{ width: "100%" }}>
                <Typography sx={typoStyle}>
                  Nom :<span style={{ color: "#434242" }}>{googleBusinessInfo?.name}</span>
                </Typography>

                <Typography sx={typoStyle}>
                  Ville :
                  <span style={{ color: "#434242" }}>
                    {
                      googleBusinessInfo?.address_components?.find((el) => el.types.includes("locality"))
                        ?.long_name
                    }
                  </span>
                </Typography>

                <Typography sx={typoStyle}>
                  Site web :{" "}
                  <a
                    href={googleBusinessInfo?.website}
                    style={{
                      color: Boolean(googleBusinessInfo?.website) ? "#19A7CE" : "#434242",
                      textDecoration: "none",
                    }}
                  >
                    {Boolean(googleBusinessInfo?.website) ? googleBusinessInfo?.website : "_______"}
                  </a>
                </Typography>

                <Typography sx={typoStyle}>
                  Téléphone :
                  <span style={{ color: "#434242" }}>{googleBusinessInfo?.formatted_phone_number}</span>
                </Typography>

                <Typography sx={typoStyle}>
                  Owner : <span style={{ color: "#434242" }}>_______</span>
                </Typography>

                <Typography sx={typoStyle}>
                  Email : <span style={{ color: "#434242" }}>_______</span>
                </Typography>

                <Typography sx={typoStyle}>
                  Réservation :
                  <span style={{ color: "#434242" }}>
                    {Boolean(googleBusinessInfo?.reservable) ? "Inclus" : "Non Inclus"}
                  </span>
                </Typography>

                <Typography sx={typoStyle}>
                  Réservation Link :{" "}
                  <a
                    href={googleBusinessInfo?.website}
                    style={{
                      color: Boolean(googleBusinessInfo?.website) ? "#19A7CE" : "#434242",
                      textDecoration: "none",
                    }}
                  >
                    {Boolean(googleBusinessInfo?.reservable) ? googleBusinessInfo?.website : ""}
                  </a>
                </Typography>

                <Typography sx={typoStyle}>
                  Menu :
                  <span style={{ color: "#434242" }}>
                    {Boolean(googleBusinessInfo?.menu) ? "Inclus" : "Non Inclus"}
                  </span>
                </Typography>

                <Typography sx={typoStyle}>
                  Menu Link :{" "}
                  <a
                    href={googleBusinessInfo?.website}
                    style={{
                      color: Boolean(googleBusinessInfo?.website) ? "#19A7CE" : "#434242",
                      textDecoration: "none",
                    }}
                  >
                    {Boolean(googleBusinessInfo?.menu) ? googleBusinessInfo?.website : ""}
                  </a>
                </Typography>
              </Stack>
            </Stack>

            <Stack
              component={Paper}
              elevation={3}
              spacing={4}
              sx={{ padding: "15px", width: { xs: "100%", md: "48%", lg: "28%" } }}
            >
              <Typography variant="h5" sx={{ textAlign: "center" }}>
                Trip Advisor
              </Typography>
              <Stack
                sx={{
                  width: "100%",
                  height: "100%",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <Typography>Coming Soon...</Typography>
              </Stack>
            </Stack>

            <Stack
              component={Paper}
              elevation={3}
              spacing={4}
              sx={{ padding: "15px", width: { xs: "100%", md: "48%", lg: "28%" } }}
            >
              <Typography variant="h5" sx={{ textAlign: "center" }}>
                Vos informations
              </Typography>
              <Stack spacing={2} sx={{ width: "100%" }}>
                <TextField                
                  id={auditData.nomRestaurant}
                  value={auditData.nomRestaurant}
                  onChange={(e) => handleChange("nomRestaurant", e.target.value)}
                  label="Nom"
                  size="small"
                  type="text"
                />

                <TextField              
                  value={auditData.city}
                  onChange={(e) => handleChange("city", e.target.value)}
                  label="Ville"
                  size="small"
                  type="text"
                  id={auditData.city}
                />

                <TextField
                  id={auditData.siteWeb}
                  value={auditData.siteWeb}
                  onChange={(e) => handleChange("siteWeb", e.target.value)}
                  label="Site Web"
                  size="small"
                  type="text"
                />

                <TextField
                  id={auditData.phone}
                  value={auditData.phone}
                  onChange={(e) => handleChange("phone", e.target.value)}
                  label="Téléphone"
                  size="small"
                  type="tel"
                />

                <TextField
                  id={auditData.email}
                  value={auditData.email}
                  onChange={(e) => handleChange("email", e.target.value)}
                  label="Email"
                  size="small"
                  type="email"
                />

                <TextField
                  id={auditData.Owner}
                  value={auditData.Owner}
                  onChange={(e) => handleChange("Owner", e.target.value)}
                  label="Owner"
                  size="small"
                  type="text"
                />

                <Box>
                  <Typography>Réservation</Typography>
                  <RadioGroup 
                    id={auditData.Booking}
                    value={auditData.Booking} 
                    row 
                    onChange={(e) => handleChange("Booking", e.target.value)}
                  >
                    <FormControlLabel value="yes" control={<Radio />} label="Oui" />
                    <FormControlLabel value="no" control={<Radio />} label="No" />
                    <FormControlLabel value="na" control={<Radio/>} label="Je ne sais pas" />
                  </RadioGroup>
                </Box>

                <TextField
                  disabled = {auditData.Booking !== "yes" }
                  id={auditData.BookingLink}
                  value={auditData.BookingLink}
                  onChange={(e) => handleChange("BookingLink", e.target.value)}
                  label="Réservation lien"
                  size="small"
                  type="text"
                />

                 <Box>
                  <Typography>Menu</Typography>
                  <RadioGroup
                    id={auditData.Menu}
                    value={auditData.Menu} 
                    row 
                    onChange={(e) => handleChange("Menu", e.target.value)}
                  >
                    <FormControlLabel value="yes" control={<Radio />} label="Oui" />
                    <FormControlLabel value="no" control={<Radio />} label="No" />
                    <FormControlLabel value="na" control={<Radio/>} label="Je ne sais pas" />
                  </RadioGroup>
                </Box>
                <TextField
                  disabled = {auditData.Menu !== "yes"}
                  id={auditData.MenuLink}
                  value={auditData.MenuLink}
                  onChange={(e) => handleChange("MenuLink", e.target.value)}
                  label="Menu lien"
                  size="small"
                  type="text"
                />
              </Stack>
            </Stack>

            <Stack
              direction="row"
              spacing={2}
              sx={{
                width: { xs: "100%", md: "48%", lg: "100%" },
                justifyContent: "flex-end",
                alignItems: "flex-end",
              }}
            >
              <Button
                onClick={handleCloseModal}
                disableElevation
                variant="outlined"
                size="small"
              >
                Annuler
              </Button>
              <Button onClick={handleSaveData} disableElevation variant="contained" size="small">
                Confirmer
              </Button>
            </Stack>
          </Stack>
        )}
      </Box>
    </Modal>
  );
};

export default ModalEditRestau;
