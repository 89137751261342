import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faSearch,
} from "@fortawesome/free-solid-svg-icons";
import {
  Col,
  Row,
  Form,
  InputGroup,
} from "@themesberg/react-bootstrap";

import { ClientTable } from "../components/Tables";
import { useDispatch, useSelector } from "react-redux";
import { getAffiliationClient } from "../redux/client/actions";

export default () => {
  // const [client, setClient] = React.useState([]);
  const [filter, setFilter] = React.useState([]);
  const [value, setValue] = React.useState([]);
  const dispatch = useDispatch();
  const { client } = useSelector(state => state.Client)

  React.useEffect(() => {
    dispatch(getAffiliationClient())
  }, []);

  

  return (
    <>
      <div className="table-settings mb-4">
        <Row className="justify-content-between align-items-center">
          <Col xs={8} md={6} lg={3} xl={4}>
            <InputGroup>
              <InputGroup.Text>
                <FontAwesomeIcon icon={faSearch} />
              </InputGroup.Text>
              <Form.Control
                type="text"
                placeholder="Search"
                value={value}
                onChange={(e) => {
                  const dd = e.target.value;
                  setValue(dd);
                  setFilter((data) => {
                    const filterData = data.filter(
                      (f) =>
                        f.title?.toLowerCase() == dd?.toLowerCase() ||
                        f.city?.toLowerCase() == dd?.toLowerCase()
                    );
                    if (filterData.length !== 0) return filterData;
                    return client;
                  });
                }}
              />
            </InputGroup>
          </Col>
        </Row>
      </div>
      <ClientTable client={client} />
    </>
  );
};
