import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import useQuery from "@tanstack/react-query";
import { faEye } from "@fortawesome/free-solid-svg-icons";
import {
  Col,
  Row,
  Nav,
  Card,
  Image,
  Button,
  Table,
  Dropdown,
  ProgressBar,
  Pagination,
  ButtonGroup,
  DropdownButton,
} from "@themesberg/react-bootstrap";

// import transactions from "../data/transactions";
// import client from "../data/client";
// import commands from "../data/commands";

export const TransactionsTable = () => {
  // const totalTransactions = transactions.length;

  const TableRow = (props) => {
    const { invoiceNumber, subscription, price, issueDate, dueDate, status } =
      props;
    const statusVariant =
      status === "Paid"
        ? "success"
        : status === "Due"
          ? "warning"
          : status === "Canceled"
            ? "danger"
            : "primary";

    return (
      <tr>
        <td>
          <span className="fw-normal">{invoiceNumber}</span>
        </td>
        <td>
          <span className="fw-normal">{subscription}</span>
        </td>
        <td>
          <span className="fw-normal">{issueDate}</span>
        </td>
        <td>
          <span className="fw-normal">{dueDate}</span>
        </td>
        {/* <td>
          <span className="fw-normal">${parseFloat(price).toFixed(2)}</span>
        </td>
        <td>
          <span className={`fw-normal text-${statusVariant}`}>{status}</span>
        </td> */}
        {/* <td>
          <Dropdown as={ButtonGroup}>
            <Dropdown.Toggle
              as={Button}
              split
              variant="link"
              className="text-dark m-0 p-0"
            >
              <span className="icon icon-sm">
                <FontAwesomeIcon icon={faEllipsisH} className="icon-dark" />
              </span>
            </Dropdown.Toggle>
            <Dropdown.Menu>
              <Dropdown.Item>
                <FontAwesomeIcon icon={faEye} className="me-2" /> View Details
              </Dropdown.Item>
              <Dropdown.Item>
                <FontAwesomeIcon icon={faEdit} className="me-2" /> Edit
              </Dropdown.Item>
              <Dropdown.Item className="text-danger">
                <FontAwesomeIcon icon={faTrashAlt} className="me-2" /> Remove
              </Dropdown.Item>
            </Dropdown.Menu>
          </Dropdown>
        </td> */}
      </tr>
    );
  };

  return (
    <Card border="light" className="table-wrapper table-responsive shadow-sm">
      <Card.Body className="pt-0">
        <Table hover className="user-table align-items-center">
          <thead>
            <tr>
              <th className="border-bottom">Nom du restaurent</th>
              <th className="border-bottom">Date de paiement</th>
              <th className="border-bottom">Montant</th>
              <th className="border-bottom">Commision</th>

              {/* <th className="border-bottom">Action</th> */}
            </tr>
          </thead>
          {/* <tbody>
            {transactions.map((t) => (
              <TableRow key={`transaction-${t.invoiceNumber}`} {...t} />
            ))}
          </tbody> */}
        </Table>
        <Card.Footer className="px-3 border-0 d-lg-flex align-items-center justify-content-between">
          <Nav>
            <Pagination className="mb-2 mb-lg-0">
              <Pagination.Prev>Previous</Pagination.Prev>
              <Pagination.Item active>1</Pagination.Item>
              <Pagination.Item>2</Pagination.Item>
              <Pagination.Item>3</Pagination.Item>
              <Pagination.Item>4</Pagination.Item>
              <Pagination.Item>5</Pagination.Item>
              <Pagination.Next>Next</Pagination.Next>
            </Pagination>
          </Nav>
          <small className="fw-bold">
            {/* Showing <b>{totalTransactions}</b> out of <b>25</b> entries */}
          </small>
        </Card.Footer>
      </Card.Body>
    </Card>
  );
};

export const ClientTable = ({ client }) => {
  // const totalClient = client.length;

  const TableRow = (props) => {
    const { title, city, createAt, packName } = props;
    const Calcul = () => {
      let price = 0;
      return price;
      packName.map((item) => {
        if (item === "free") return 0;
        if (item === "visibility") price += 500;
        if (item === "order") price += 1000;
        if (item === "booking") price += 1500;
        if (item === "custom") {
          //TODO: get custom module prices
        }
      });
      return price;
    };
    return (
      <tr>
        <td>
          <span className="fw-normal">{title}</span>
        </td>
        <td>
          <span className="fw-normal">{city}</span>
        </td>
        <td>
          <span className="fw-normal">{createAt}</span>
        </td>
        {!packName?.includes("free") ? (
          <td className="packname" style={{ display: "flex" }}>
            <span className="fw-normal">payant</span>
            <td>
              <Dropdown as={ButtonGroup}>
                <Dropdown.Toggle
                  as={Button}
                  split
                  variant="link"
                  className="text-dark m-0 p-0"
                >
                  <span className="icon icon-sm">
                    <FontAwesomeIcon icon={faEye} className="icon-dark" />
                  </span>
                </Dropdown.Toggle>
                <Dropdown.Menu>
                  <Dropdown style={{ padding: "10px", display: "block" }}>
                    {packName?.join(" | ")}
                  </Dropdown>
                </Dropdown.Menu>
              </Dropdown>
            </td>
          </td>
        ) : (
          <td>
            <span className="fw-normal">{packName}</span>
          </td>
        )}
        <td>
          <span className="fw-normal">{Calcul()} Dh</span>
        </td>
      </tr>
    );
  };

  return (
    <Card border="light" className="table-wrapper table-responsive shadow-sm">
      <Card.Body className="pt-0">
        <Table hover className="user-table align-items-center">
          <thead>
            <tr>
              <th className="border-bottom">Nom du restaurent</th>
              <th className="border-bottom">Ville</th>
              <th className="border-bottom">Date</th>
              <th className="border-bottom">Abonnement</th>
              <th className="border-bottom">Montant</th>

              {/* <th className="border-bottom">Action</th> */}
            </tr>
          </thead>
          <tbody>
            {client.map((t) => (
              <TableRow {...t} />
            ))}
          </tbody>
        </Table>
        <Card.Footer className="px-3 border-0 d-lg-flex align-items-center justify-content-between">
          <Nav>
            <Pagination className="mb-2 mb-lg-0">
              <Pagination.Prev>Previous</Pagination.Prev>
              <Pagination.Item active>1</Pagination.Item>
              <Pagination.Item>2</Pagination.Item>
              <Pagination.Item>3</Pagination.Item>
              <Pagination.Item>4</Pagination.Item>
              <Pagination.Item>5</Pagination.Item>
              <Pagination.Next>Next</Pagination.Next>
            </Pagination>
          </Nav>
          <small className="fw-bold">
            {/* Showing <b>{totalClient}</b> out of <b>25</b> entries */}
          </small>
        </Card.Footer>
      </Card.Body>
    </Card>
  );
};
