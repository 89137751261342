import { Box, TextField } from "@mui/material";
import React from "react";

const EditableInput = ({
  isActive,
  activeIcon, 
  icon,
  editedData,
  setEditedData,
  id,
  value,
  style, 
  type = "text", 
  isLink = false, 
  iconLink = ""
}) => {
  return (
    <Box sx={{ display: "flex", justifyContent: "flex-start", alignItems: "center", gap: "5px", ...style }}>
      {isActive ?  ( activeIcon ) : (icon)}

      {isActive ? (
        <TextField
          type = {type}
          fullWidth
          size="small"
          variant="outlined"
          value={editedData[id]?.value}
          onChange={(e) =>
            setEditedData(
              editedData.map((el) =>
                el.id === id ? { ...editedData[id], value: e.target.value } : el
              )
            )
          }
          inputProps={{ style: { padding: "4px 14px" } }}
        />
      ) : (
        isLink ? iconLink : value
      )}
    </Box>
  );
};

export default EditableInput;
