import { all } from 'redux-saga/effects';

import usersSaga from './users/saga';
import ClientSaga from "./client/saga"
import ContactSaga from './partnerContact/saga';
import partnerRestaurantContact from './partnerRestaurantContact/saga';
import googleBusinessSaga from './googleBusiness/saga';
import ServicesSaga from './partnerServices/saga';

export default function* rootSaga() {
  yield all([
    usersSaga(),
    ClientSaga(),
    ContactSaga(),
    partnerRestaurantContact(),
    googleBusinessSaga(),
    ServicesSaga()
  ]);
}