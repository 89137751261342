import React, { useState } from 'react';
import PlacesAutocomplete from 'react-places-autocomplete';
import styles from "./style.module.scss";
// import {InputComponent} from "../elements";
import axios from "axios";
import { Form, InputGroup } from '@themesberg/react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSearch } from '@fortawesome/free-solid-svg-icons';
import { useDispatch } from 'react-redux';
import { getGoogleBusinessData } from '../../redux/googleBusiness/actions';


const placeDetails = (data) => {

    const html = `<div>${data.adr_address}</div>`
    const dom_doc = new DOMParser().parseFromString(html, "text/xml");

    let countryName = dom_doc.querySelectorAll(".country-name")
    countryName = countryName.length > 0 ? countryName[0].childNodes[0].textContent : "";

    let postalCode = dom_doc.querySelectorAll(".postal-code");
    postalCode = postalCode.length > 0 ? postalCode[0].childNodes[0].textContent : "";

    let locality = dom_doc.querySelectorAll(".locality")
    locality = locality.length > 0 ? locality[0].childNodes[0].textContent : "";


    const website = data.website;
    const international_phone_number = data.international_phone_number ? data.international_phone_number.replace("+", "") : "";
    const name = data.name;
    const formatted_address = data.formatted_address;
    const utc_offset = data.utc_offset;
    const geometry = data.geometry.location;


    return {
        website,
        international_phone_number,
        name,
        formatted_address,
        utc_offset,
        geometry,
        locality,
        postalCode,
        countryName
    }

}

const LocationSearchInput = () => {
    const [adress, setAadress] = useState('')

    const defaultProps = {
        getName: () => null,
        getFormattedAddress: () => null,
        getLocality: () => null,
        getLatLng: () => null,
        getWebsite: () => null,
        getShortName: () => null,
        getUtcOffset: () => null,
        getPhone: () => null,
        getPlaceID: () => null,
        getCountryName: () => null,
        getPostalCode: () => null,
        placeholder: "Find your restaurant on google"
    }



    const handleChange = (address) => {
        setAadress(address);
    };
    const dispatch = useDispatch()
    // When the user selects an autocomplete suggestion...
    const handleSelect = async (address, placeId, suggestion) => {
        handleChange(address);

        dispatch(getGoogleBusinessData(placeId))


    };

    const myCallbackFunc = (result) => { };


    const renderInput = ({
        getInputProps,
        getSuggestionItemProps,
        suggestions,
        placeholder
    }) => (
        <div className={styles.container}>

            <InputGroup>
                <InputGroup.Text>
                    <FontAwesomeIcon icon={faSearch} />
                </InputGroup.Text>
                <Form.Control {...getInputProps()} type="text" placeholder="Search" />
            </InputGroup>
            {/* <input
                            name="serch"
                            // styleField={{ marginBottom: "20px" }}
                            {...getInputProps()}
                        // labelName={this.props.placeholder}
                        // errorMsg={this.props.errorMsg}
                        /> */}

            {suggestions.length > 0 &&
                <div className={styles.autocomplete_dropdown_container}>
                    {suggestions.map((suggestion, index) => {
                        return (
                            <div
                                {...getSuggestionItemProps(suggestion)}
                                className={styles.suggestion}
                                key={index}
                            >
                                <i className="mdi mdi-map-marker"></i>
                                <span>{suggestion.description}</span>
                            </div>
                        );
                    })}
                </div>
            }
        </div>
    );

    // Limit the suggestions to show only cities in the US
    const searchOptions = {
        // types: ['(cities)'],
        // types: [`(restaurant)`],
        types: ['establishment',],
        fields: [
            'address_component',
            'adr_address',
            'business_status',
            'formatted_address',
            'geometry',
            'icon',
            'name',
            'business_status',
            'formatted_phone_number',
            'international_phone_number',
        ],
        // componentRestrictions: { country: 'ma' },
    };

    return (
        <PlacesAutocomplete
            value={adress}
            onChange={handleChange}
            onSelect={handleSelect}
            // Pass the search options prop
            searchOptions={searchOptions}
        >
            {renderInput}
        </PlacesAutocomplete>
    );

}



export default LocationSearchInput;
export {
    placeDetails
}
