// @flow
import {
  GET_AFFILIATION_CLIENT,
  GET_AFFILIATION_CLIENT_SUCCESS,
  GET_AFFILIATION_CLIENT_FAILED
} from '../constants/actionTypes';

export const getAffiliationClient = (username, password, callback) => ({
  type: GET_AFFILIATION_CLIENT,
  payload: { username, password, callback },
});
export const getAffiliationClientSuccess = (client) => ({
  type: GET_AFFILIATION_CLIENT_SUCCESS,
  payload: client,
});

export const getAffiliationClientFailed = (error) => ({
  type: GET_AFFILIATION_CLIENT_FAILED,
  payload: error,
});