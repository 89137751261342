import { EDIT_CONTACT_BY_FIELD, EDIT_CONTACT_BY_FIELD_FAILED, EDIT_CONTACT_BY_FIELD_SUCCESS, SET_CONTACT_TO_RESTAURANT, SET_CONTACT_TO_RESTAURANT_FAILED, SET_CONTACT_TO_RESTAURANT_SUCCESS, SET_PARTNER_CONTACTS, SET_PARTNER_CONTACTS_FAILED, SET_PARTNER_CONTACTS_SUCCESS } from "../constants/actionTypes";

export const setPartnerContacts = (data, page, filter) => ({
  type: SET_PARTNER_CONTACTS,
  payload: { data, page, filter },
});
export const setPartnerContactsSuccess = (client) => ({
  type: SET_PARTNER_CONTACTS_SUCCESS,
  payload: client,
});

export const setPartnerContactsFailed = (error) => ({
  type: SET_PARTNER_CONTACTS_FAILED,
  payload: error,
});


export const setContactToRestaurant = (data) => ({
  type: SET_CONTACT_TO_RESTAURANT,
  payload: data
})

export const setContactToRestaurantSuccess = (client) => ({
  type: SET_CONTACT_TO_RESTAURANT_SUCCESS,
  payload: client
})

export const setContactToRestaurantFailed = (client) => ({
  type: SET_CONTACT_TO_RESTAURANT_FAILED,
  payload: client
})

export const editContactByField = (data) => ({
  type: EDIT_CONTACT_BY_FIELD,
  payload: data
})

export const editContactByFieldSuccess = (data) => ({
  type: EDIT_CONTACT_BY_FIELD_SUCCESS,
  payload: data
})

export const editContactByFieldFailed = (error) => ({
  type: EDIT_CONTACT_BY_FIELD_FAILED,
  payload: error
})