import { httpClient_get, httpClient_post } from '../../helpers/api';

const setContactPartnerServices = (data) => {
    return httpClient_post('/partners/add_restaurant_partner_contact', {
        ...data
    })
        .then((response) => {
            console.log("-=-=->>>>>>", response.data)
            return response.data;
        })
        .catch((error) => {
            throw error.response.data;
        });
};


const editRestaurantByFieldServices = (data) => {
    return httpClient_post('/partners/edit_restaurant_partner', {
        ...data
    })
        .then((response) => {
            console.log("-=-=->>>>>>", response.data)
            return response.data;
        })
        .catch((error) => {
            throw error.response.data;
        });
};

const getRestaurantByIDService = (data) => {
    return httpClient_get('/partners/get_restaurant_by_id', {...data})
    .then(res => {
        return res.data;
    })
    .catch(err =>{
        throw err.response.data
    })
}

const editRestaurantByIDService = (data) => {
    return httpClient_post("/partners/edit_restaurant_by_id", {...data})
    .then(res => {
        return res.data
    })
    .catch(err => {
        throw err.response.data
    })
}

const getPartnerStatisticsService = (data) => {
    return httpClient_get("/partners/get_partner_statistics", {...data})
    .then(res => {
        return res.data;
    })
    .catch(err => {
        throw err.response.data
    })
} 
export {
    setContactPartnerServices,
    editRestaurantByFieldServices, 
    getRestaurantByIDService, 
    editRestaurantByIDService,
    getPartnerStatisticsService
};