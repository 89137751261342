import {
  Button,
  FormControl,
  FormControlLabel,
  InputLabel,
  MenuItem,
  Modal,
  Select,
  Stack,
  Switch,
  TextField,
  Typography,
} from "@mui/material";
import React from "react";
import { useSelector } from "react-redux";
import PhotoCameraIcon from "@mui/icons-material/PhotoCamera";
import { useRef } from "react";
import { makeStyles } from "@mui/styles";

const useStyles = makeStyles({
  input: {
    "& input[type=number]": {
      "-moz-appearance": "textfield",
    },
    "& input[type=number]::-webkit-outer-spin-button": {
      "-webkit-appearance": "none",
      margin: 0,
    },
    "& input[type=number]::-webkit-inner-spin-button": {
      "-webkit-appearance": "none",
      margin: 0,
    },
  },
  boxStyle: {
    backgroundColor: "#fff",
    padding: "25px",
    borderRadius: "15px",
    border: "none",
    alignItems: "center",
  },
});

const ModalAddService = ({ open, handleClose, handleChange, data, handleSaveData }) => {
  const { contacts: restaurants } = useSelector((state) => state.PartnerRestaurantContact);
  const imageRef = useRef(null);
  const classes = useStyles();

  return (
    <Modal
      sx={{ display: "flex", justifyContent: "center", alignItems: "center" }}
      open={open}
      onClose={handleClose}
    >
      <Stack spacing={4} className={classes.boxStyle} sx={{ width: { xs: "90%", md: "650px" } }}>
        <Typography variant="h5">Ajouter une service</Typography>
        <Stack spacing={2} sx={{ width: "100%" }}>
          <Stack
            onClick={() => imageRef.current.click()}
            direction="row"
            justifyContent={"space-between"}
            alignItems={"center"}
            sx={{
              width: "100%",
              height: "40px",
              border: "1px solid #CCC",
              padding: "9px 14px",
              borderRadius: "3px",
              cursor: "pointer",
            }}
          >
            <Typography>
              {data.find((el) => el.name === "file")?.value
                ? data.find((el) => el.name === "file")?.value?.name
                : "Upload"}
            </Typography>
            <PhotoCameraIcon />
            <input
              id="upload"
              name="upload"
              type="file"
              ref={imageRef}
              hidden
              accept=".jpg"
              onChange={(e) => handleChange("file", e.target.files[0])}
            />
          </Stack>

          <TextField
            fullWidth
            variant="outlined"
            size="small"
            label={data.find((el) => el.name === "nameService")?.rowName}
            value={data.find((el) => el.name === "nameService")?.value}
            onChange={(e) =>
              handleChange(data.find((el) => el.name === "nameService")?.name, e.target.value)
            }
          />

          <TextField
            fullWidth
            variant="outlined"
            size="small"
            label={data.find((el) => el.name === "shortDesc")?.rowName}
            value={data.find((el) => el.name === "shortDesc")?.value}
            onChange={(e) =>
              handleChange(data.find((el) => el.name === "shortDesc")?.name, e.target.value)
            }
          />

          <TextField
            fullWidth
            variant="outlined"
            multiline
            rows={3}
            maxRows={3}
            size="small"
            label={data.find((el) => el.name === "longDesc")?.rowName}
            value={data.find((el) => el.name === "longDesc")?.value}
            onChange={(e) =>
              handleChange(data.find((el) => el.name === "longDesc")?.name, e.target.value)
            }
          />

          <Stack direction="row" spacing={2} justifyContent={"space-between"} alignItems={"center"}>
            <TextField
              sx={{ width: "50%", "& .MuiInputBase-root": { padding: 0 } }}
              variant="outlined"
              size="small"
              type="number"
              className={classes.input}
              label={data.find((el) => el.name === "price")?.rowName}
              value={data.find((el) => el.name === "price")?.value}
              onChange={(e) =>
                handleChange(data.find((el) => el.name === "price")?.name, e.target.value)
              }
              InputProps={{
                endAdornment: (
                  <Select
                    sx={{ width: "100px", ".MuiOutlinedInput-notchedOutline": { border: "unset" } }}
                    size="small"
                    value={data.find((el) => el.name === "unit")?.value}
                    onChange={(e) => {
                      handleChange(data.find((el) => el.name === "unit")?.name, e.target.value);
                    }}
                  >
                    <MenuItem value="none">None</MenuItem>
                    <MenuItem value="Kg">Kg</MenuItem>
                    <MenuItem value="L">L</MenuItem>
                    <MenuItem value="Unit">Unit</MenuItem>
                    <MenuItem value="mois">Mois</MenuItem>
                  </Select>
                ),
              }}
            />
            <TextField
              sx={{ width: "50%" }}
              variant="outlined"
              size="small"
              label={data.find((el) => el.name === "commission")?.rowName}
              value={data.find((el) => el.name === "commission")?.value}
              onChange={(e) =>
                handleChange(data.find((el) => el.name === "commission")?.name, e.target.value)
              }
            />
          </Stack>
          <FormControl size="small" fullWidth>
            <InputLabel id="selectId">
              {data.find((el) => el.name === "clients")?.rowName}
            </InputLabel>
            <Select
              labelId="selectId"
              size="small"
              multiple
              label={data.find((el) => el.name === "clients")?.rowName}
              value={data.find((el) => el.name === "clients")?.value}
              onChange={(e) =>
                handleChange(data.find((el) => el.name === "clients")?.name, e.target.value)
              }
            >
              {restaurants?.map((restau) => {
                return (
                  <MenuItem key={restau._id} value={restau._id}>
                    {restau.nomRestaurant}
                  </MenuItem>
                );
              })}
            </Select>
          </FormControl>
          <FormControlLabel
            control={
              <Switch
                checked={data.find((el) => el.name === "visible")?.value}
                onChange={(e) =>
                  handleChange(data.find((el) => el.name === "visible")?.name, e.target.checked)
                }
              />
            }
            label={data.find((el) => el.name === "visible")?.rowName}
          />
          <Button
            sx={{ alignSelf: "flex-end" }}
            disableElevation
            variant="contained"
            size="small"
            onClick={handleSaveData}
          >
            Enregistrer
          </Button>
        </Stack>
      </Stack>
    </Modal>
  );
};

export default ModalAddService;
