import { Box, Button, Modal, Stack, Typography } from "@mui/material";
import React from "react";

const boxStyle = {
  backgroundColor: "#fff",
  width: { xs: "90%", md: "500px" },
  padding: "40px 40px 20px 40px",
  borderRadius: "15px",
  border: "none",
  display: "flex",
  flexDirection: "column",
  gap: "10px",
  alignItems: "center",
};

const ModalArchiveRestau = ({ open, handleClose, row, handleSave }) => {
  return (
    <Modal
      style={{ display: "flex", justifyContent: "center", alignItems: "center" }}
      open={open}
      onClose={handleClose}
    >
      <Box sx={boxStyle}>
        <Typography variant="h3">Attention!</Typography>
        <Typography sx={{ marginBottom: "35px" }} variant="h6">
          êtes-vous sûr de vouloir supprimer {row?.nomRestaurant}
        </Typography>
        <Stack spacing={2} direction="row">
          <Button
            sx={{
              color: "#000",
              borderColor: "#000",
              "&:hover": { color: "#000", borderColor: "#000" },
            }}
            onClick={handleClose}
            disableElevation
            variant="outlined"
            size="small"
          >
            Annuler
          </Button>
          <Button
            color="warning"
            onClick={() => handleSave(row?._id)}
            disableElevation
            variant="contained"
            size="small"
          >
            Confirmer
          </Button>
        </Stack>
      </Box>
    </Modal>
  );
};

export default ModalArchiveRestau;
