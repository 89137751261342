
import { REHYDRATE } from 'redux-persist';
import { EDIT_CONTACT_BY_FIELD, EDIT_CONTACT_BY_FIELD_FAILED, EDIT_CONTACT_BY_FIELD_SUCCESS, SET_CONTACT_TO_RESTAURANT, SET_CONTACT_TO_RESTAURANT_FAILED, SET_CONTACT_TO_RESTAURANT_SUCCESS, SET_PARTNER_CONTACTS, SET_PARTNER_CONTACTS_SUCCESS, SET_PARTNER_RESAUTRANT_CONTACTS_FAILED } from '../constants/actionTypes';

const INIT_STATE = {
    contacts: [],
    isLoading: false,
    error: null,
    pageCount: 0,
}
const PartnerContact = (state = INIT_STATE, action) => {

    switch (action.type) {

        case SET_PARTNER_CONTACTS:
            return { ...state, isLoading: true, error: null }
        case SET_PARTNER_CONTACTS_SUCCESS:
            return { ...state, isLoading: false, error: null, contacts: action.payload.data, pageCount: action.payload.pageCount }
        case SET_PARTNER_RESAUTRANT_CONTACTS_FAILED:
            return { ...state, isLoading: false, error: action.payload }


        case SET_CONTACT_TO_RESTAURANT:
            return { ...state, isLoading: true, error: null }
        case SET_CONTACT_TO_RESTAURANT_SUCCESS:
            return { ...state, isLoading: false, error: null, 
                        contacts: action.payload.partnerContact, 
                        restaurant : action.payload.partnerRestaurant  
                    }
        case SET_CONTACT_TO_RESTAURANT_FAILED:
            return { ...state, isLoading: false, error: action.payload }


        case EDIT_CONTACT_BY_FIELD:
            let list = JSON.parse(JSON.stringify(state.contacts));
            let tIndex = list.findIndex((el) => el._id === action.payload.rowID);
            list[tIndex][action.payload.name] = action.payload.value;
            return { ...state, isLoading: true, error: null}
        case EDIT_CONTACT_BY_FIELD_SUCCESS:
            return { ...state, isLoading: false, error: null, contacts: action.payload.data, pageCount: action.payload.pageCount }
        case EDIT_CONTACT_BY_FIELD_FAILED:
            return { ...state, isLoading: false, error: action.payload }


        case REHYDRATE:
            return action.payload
                ? {
                    ...state,
                    ...action.payload.PartnerContact,
                }
                : {
                    ...state,
                };
        default:
            return { ...state };
    }
};

export default PartnerContact;